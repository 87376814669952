import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the FilterPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: Array<any>, key, filter, operator?) {
    operator = operator ? operator : '==';
    let nice = 'obj[key] ' + operator + ' filter';
    return value.filter(obj => eval(nice))
  }
}
