import { Component, AfterViewInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "page-search",
  templateUrl: "search.html",
})
export class SearchPage implements AfterViewInit {
  list = [];
  search = "";
  loading = false;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _categorias: CategoriasService,
    public api: ApiService,
    public _loading: LoadingService
  ) {
    this.search = this.pageParams.get("search", "search");
    this.onSearch(this.search.toLocaleLowerCase());
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit SearchPage");
  }

  onSearch(search) {
    this.search = search.toLocaleLowerCase();

    this._loading.present();
    this.loading = true;
    this.api.get("/conteudos/search?search=" + search).subscribe(
      (r: any) => {
        this._loading.dismiss();
        this.loading = false;
        this.list = r;
      },
      (e: any) => {
        this._loading.dismiss();
        this.loading = false;
      }
    );
  }
}
