import {
  Component,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from "@angular/core";

import { PageParamsService } from "src/app/services/page-params.service";
import { MenuController, NavController } from "@ionic/angular";

/**
 * Generated class for the HeaderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "header",
  templateUrl: "header.html",
  styleUrls: ["header.scss"],
})
export class HeaderComponent {
  @Input() title: string;
  @Input() searchPage = false;
  @Input() showMenu = true;
  @Input() showBackButton = false;
  @Input() showPerfil = false;
  @Input() hideButtons = false;
  @Input() hideSearch = false;
  @Input() defaultHref = "/aplicativos";
  @Output() searchSubmit = new EventEmitter<any>();
  search = "";
  searchOpen = false;

  @ViewChild("input", { read: ElementRef }) input: ElementRef;
  openMenu() {
    this.menu.enable(true, "first");
    this.menu.open("first");
  }
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    private menu: MenuController
  ) {}

  doSearch() {
    if (this.searchPage) {
      this.searchSubmit.next(this.search);
    } else {
      this.navCtrl.navigateForward("/search");
      this.pageParams.set("search", { search: this.search });
    }
    this.closeSearch();
  }

  closeSearch() {
    this.searchOpen = false;
    setTimeout(() => (this.search = ""), 500);
  }
  openSearch() {
    this.input.nativeElement.focus();
    this.searchOpen = true;
  }

  toggleSearch() {
    this.searchOpen = !this.searchOpen;
  }

  perfilPage() {
    this.navCtrl.navigateForward("/perfil");
  }
}
