import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NavController, ToastController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AuthService } from "src/app/services/auth.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-contato-form",
  templateUrl: "./contato-form.component.html",
  styleUrls: ["./contato-form.component.scss"],
})
export class ContatoFormComponent {
  contato: any = {
    nome: this.auth.user.user.first_name,
    telefone: this.auth.user.user.telefone,
    email: this.auth.user.user.email,
    mensagem: "",
  };
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public auth: AuthService,
    public helpers: HelpersService,
    public toastCtrl: ToastController,
    public api: ApiService,
    public loading: LoadingService,
    public aplicativos: AplicativosService
  ) {}

  ngAfterViewInit() {
    console.log("ngAfterViewInit ContatoPage");
  }

  async onSubmit(form: NgForm) {
    if (this.aplicativos.item.id) {
      this.contato.aplicativo_id = this.aplicativos.item.id;
    } else {
      this.contato.aplicativo_id = null;
    }
    if (!this.helpers.isEmail(this.contato.email)) {
      (
        await this.toastCtrl.create({
          message: "Digite um e-mail válido",
          duration: 4000,
        })
      ).present();
      return;
    }
    if (!form.valid) {
      (
        await this.toastCtrl.create({
          message: "Verifique se todos os campos estão preenchidos",
          duration: 4000,
        })
      ).present();
      return;
    }
    await this.loading.present();
    this.api.post("/contato", this.contato).subscribe(
      async (r: any) => {
        this.loading.dismiss();
        (
          await this.toastCtrl.create({
            message:
              "Sua mensagem foi enviada, responderemos o mais breve possível!",
            duration: 4000,
          })
        ).present();
        this.contato = {
          nome: this.auth.user.user.first_name,
          telefone: this.auth.user.user.telefone,
          email: this.auth.user.user.email,
          mensagem: "",
        };
      },
      (e) => this.loading.dismiss()
    );
  }
}
