/**
 * Generated class for the CodigoPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
import { HttpErrorResponse } from "@angular/common/http";
import { Component, AfterViewInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import {
  NavController,
  ToastController,
  AlertController,
} from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { PreviousRouteService } from "src/app/services/previous-route.service";
@Component({
  selector: "page-codigo",
  templateUrl: "codigo.html",
})
export class CodigoPage implements AfterViewInit {
  code = "";
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public loading: LoadingService,
    public toastCtrl: ToastController,
    public api: ApiService,
    public auth: AuthService,
    public alertCtrl: AlertController,
    public helpers: HelpersService,
    public global: GlobalService,
    public _categorias: CategoriasService,
    public previousRoute: PreviousRouteService
  ) {}

  ngAfterViewInit() {}

  ionViewWillEnter() {
    this.api
      .post("/users/" + this.auth.user.user.id, {}, false)
      .subscribe((r: any) => this.auth.setToken(r.token));
  }
  async onSubmit(form: NgForm) {
    if (this.code.length == 0) {
      (
        await this.toastCtrl.create({
          message: "Você deve informar um código",
          duration: 4000,
        })
      ).present();
      return;
    }

    await this.loading.present();
    this.api
      .post(
        "/users/" + this.auth.user.user.id + "/premium",
        { code: this.code },
        false
      )
      .subscribe(
        async (r: any) => {
          this.loading.dismiss();
          this.code = "";
          console.log(this.auth.user.user);
          this.auth.setToken(r.token);
          console.log(this.auth.user.user);
          (
            await this.alertCtrl.create({
              header: "Sucesso!",
              message:
                "Seu código foi ativado com sucesso, ele irá expirar em " +
                this.helpers
                  .moment(
                    this.auth.user.user.premium_expire_at,
                    "YYYY-MM-DD HH:mm:ss"
                  )
                  .format("LLL"),
              buttons: [{ text: "Ok" }],
            })
          ).present();
          if (this.previousRoute.getPreviousUrl()) {
            this.navCtrl.pop();
          }
          this._categorias.getAll();
        },
        async (e: HttpErrorResponse) => {
          this.loading.dismiss();
          this.code = "";
          console.log(e);
          if (e.error.message) {
            (
              await this.toastCtrl.create({
                message: e.error.message,
                duration: 4000,
              })
            ).present();
          } else {
            (
              await this.toastCtrl.create({
                message:
                  "Um erro desconhecido aconteceu, por favor tente novamente",
                duration: 4000,
              })
            ).present();
          }
        }
      );
  }
}
