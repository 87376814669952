import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { PdfsService } from "src/app/services/pdfs.service";

type GestaoTab = "home" | "listagem" | "imagens" | "contato" | "cadastro";
@Component({
  templateUrl: "gestao-tabs.html",
})
export class GestaoTabsPage implements AfterViewInit, OnDestroy {
  tab: GestaoTab = "home";
  headerTitle = "GESTÃO DE CONSULTAS";
  queryParamsSub: Subscription;
  constructor(
    public pageParams: PageParamsService,
    public aplicativos: AplicativosService,
    public route: ActivatedRoute
  ) {}
  ngAfterViewInit() {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.setTab(params.tab);
      } else {
        this.setTab("home");
      }
    });
  }
  ngOnDestroy() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }

  setTab(tab: GestaoTab) {
    this.tab = tab;
    if (this.tab === "home") {
      this.headerTitle = "GESTÃO DE CONSULTAS";
    }
    if (this.tab === "cadastro") {
      this.headerTitle = "CADASTRO";
    }
    if (this.tab === "imagens") {
      this.headerTitle = "MINHAS IMAGENS";
    }
    if (this.tab === "contato") {
      this.headerTitle = "CONTATO";
    }
    if (this.tab === "listagem") {
      this.headerTitle = "LISTAGEM";
    }
  }
}
