import { AfterViewInit, Component } from "@angular/core";

import { StatusBar } from "@ionic-native/status-bar/ngx";

import { NavController, AlertController } from "@ionic/angular";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AuthService } from "src/app/services/auth.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { Router } from "@angular/router";
import { CleanupService } from "src/app/services/cleanup.service";

@Component({
  selector: "page-aplicativos",
  templateUrl: "aplicativos.html",
  styleUrls: ["aplicativos.scss"],
})
export class AplicativosPage implements AfterViewInit {
  constructor(
    public navCtrl: NavController,
    public router: Router,
    public loading: LoadingService,
    public aplicativos: AplicativosService,
    public helpers: HelpersService,
    public auth: AuthService,
    public statusBar: StatusBar,
    public alertCtrl: AlertController,
    public _categorias: CategoriasService,
    public cleanup: CleanupService
  ) {}

  ionViewDidEnter() {
    this.aplicativos.clearItem();
    this.aplicativos.clearTheme();
  }

  async ngAfterViewInit() {
    await this.loading.present();
    this.aplicativos
      .getAll()
      .then(() => this.loading.dismiss())
      .catch(() => this.loading.dismiss());
  }

  setItem(item) {
    console.log(item);
    this.aplicativos.setItem(item);
    this.navCtrl.navigateForward(this.aplicativos.getInitialPage(item));

    // limpa alguns dados que estavam salvos anteriormente
    this.cleanup.execute();
  }
}
