import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { InitialPageGuard } from "./guards/initial-page.guard";
import { IsLoggedInGuard } from "./guards/is-logged-in.guard";
import { AplicativosPage } from "./pages/aplicativos/aplicativos";
import { CadastroUsuarioSecondPage } from "./pages/cadastro-usuario-second/cadastro-usuario-second";
import { CadastroUsuarioPage } from "./pages/cadastro-usuario/cadastro-usuario";
import { CategoriasConteudosPage } from "./pages/categorias-conteudos/categorias-conteudos";
import { CentralHomePage } from "./pages/central-home/central-home";
import { CentralNoticiasInternaPage } from "./pages/central-noticias-interna/central-noticias-interna";
import { CentralNoticiasPage } from "./pages/central-noticias/central-noticias";
import { CentralNotificacoesPage } from "./pages/central-notificacoes/central-notificacoes";
import { CentralTabsPage } from "./pages/central-tabs/central-tabs";
import { CertificadosPagarPage } from "./pages/certificados-pagar/certificados-pagar";
import { CodigoPage } from "./pages/codigo/codigo";
import { ConhecaPage } from "./pages/conheca/conheca";
import { ContatoPage } from "./pages/contato/contato";
import { DefaultTabsPage } from "./pages/default-tabs/default-tabs";
import { EasymindTabsPage } from "./pages/easymind-tabs/easymind-tabs";
import { EsqueciSenhaPage } from "./pages/esqueci-senha/esqueci-senha";
import { FacaPartePage } from "./pages/faca-parte/faca-parte";
import { FavoritosPage } from "./pages/favoritos/favoritos";
import { GestaoTabsPage } from "./pages/gestao-tabs/gestao-tabs";
import { LoginCodigoPage } from "./pages/login-codigo/login-codigo";
import { LoginPage } from "./pages/login/login";
import { NutriCalculoCaloricoPage } from "./pages/nutri-calculo-calorico/nutri-calculo-calorico";
import { NutriExercicioInternaPage } from "./pages/nutri-exercicio-interna/nutri-exercicio-interna";
import { NutriExerciciosPage } from "./pages/nutri-exercicios/nutri-exercicios";
import { NutriTabsPage } from "./pages/nutri-tabs/nutri-tabs";
import { PdfsEDicasPage } from "./pages/pdfs-e-dicas/pdfs-e-dicas";
import { PerfilPage } from "./pages/perfil/perfil";
import { PlayerPage } from "./pages/player/player";
import { NutriRefeicoesInternaPage } from "./pages/refeicoes-interna/refeicoes-interna";
import { RefeicoesPage } from "./pages/refeicoes/refeicoes";
import { SearchPage } from "./pages/search/search";
import { ContratarPagar } from "./pages/contratar-pagar/contratar-pagar";
import { ContratarPage } from "./pages/contratar/contratar";

const routes: Routes = [
  {
    path: "",
    redirectTo: "aplicativos",
    pathMatch: "full",
  },

  // Auth pages
  {
    path: "login",
    component: LoginPage,
  },

  // app de empresa
  {
    path: "login-codigo",
    component: LoginCodigoPage,
  },

  {
    path: "esqueci-senha",
    component: EsqueciSenhaPage,
  },
  {
    path: "faca-parte",
    component: FacaPartePage,
  },

  // app de pessoa fisica
  {
    path: "contratar",
    component: ContratarPage,
    canActivate: [IsLoggedInGuard],
  },

  {
    path: "contratar-pagar",
    canActivate: [IsLoggedInGuard],
    component: ContratarPagar,
  },

  // Initial Pages
  {
    path: "nutri-tabs",
    component: NutriTabsPage,
    canActivate: [InitialPageGuard],
  },
  {
    path: "default-tabs",
    component: DefaultTabsPage,
    canActivate: [InitialPageGuard],
  },
  {
    path: "easymind-tabs",
    component: EasymindTabsPage,
    canActivate: [InitialPageGuard],
  },
  {
    path: "central-tabs",
    component: CentralTabsPage,
    canActivate: [InitialPageGuard],
  },
  {
    path: "gestao-tabs",
    component: GestaoTabsPage,
    canActivate: [InitialPageGuard],
  },
  {
    path: "aplicativos",
    component: AplicativosPage,
    canActivate: [IsLoggedInGuard],
  },

  // cadastro usuario não está sendo utilizado, mas era um negocio de cadastro de peso, altura, data de nascimento
  {
    path: "cadastro-usuario",
    component: CadastroUsuarioPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "cadastro-usuario-second",
    component: CadastroUsuarioSecondPage,
    canActivate: [IsLoggedInGuard],
  },

  {
    path: "categorias-conteudos",
    component: CategoriasConteudosPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "central-home",
    component: CentralHomePage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "central-noticias",
    component: CentralNoticiasPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "central-noticias-interna",
    component: CentralNoticiasInternaPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "central-notificacoes",
    component: CentralNotificacoesPage,
    canActivate: [IsLoggedInGuard],
  },

  {
    path: "certificados-pagar",
    component: CertificadosPagarPage,
    canActivate: [IsLoggedInGuard],
  },

  // não ta sendo usado
  {
    path: "codigo",
    component: CodigoPage,
    canActivate: [IsLoggedInGuard],
  },

  {
    path: "conheca",
    component: ConhecaPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "contato",
    component: ContatoPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "nutri-calculo-calorico",
    component: NutriCalculoCaloricoPage,
    canActivate: [IsLoggedInGuard],
  },

  {
    path: "nutri-exercicio-interna",
    component: NutriExercicioInternaPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "nutri-exercicios",
    component: NutriExerciciosPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "refeicoes",
    component: RefeicoesPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "refeicoes-interna",
    component: NutriRefeicoesInternaPage,
    canActivate: [IsLoggedInGuard],
  },

  {
    path: "favoritos",
    component: FavoritosPage,
    canActivate: [IsLoggedInGuard],
  },

  {
    path: "pdfs-e-dicas",
    component: PdfsEDicasPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "perfil",
    component: PerfilPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "player",
    component: PlayerPage,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "search",
    component: SearchPage,
    canActivate: [IsLoggedInGuard],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
