import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-frase-do-dia",
  templateUrl: "./frase-do-dia.component.html",
  styleUrls: ["./frase-do-dia.component.scss"],
})
export class FraseDoDiaComponent {
  frase;
  constructor(public api: ApiService) {
    this.api.get("/frases").subscribe((r: any) => {
      this.frase = r;
    });
  }
}
