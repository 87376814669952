import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { IonInfiniteScroll, NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { NutriExerciciosService } from "src/app/services/nutri-exercicios.service";
import { HelpersService } from "src/app/services/helpers.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-nutri-exercicios-content",
  templateUrl: "./nutri-exercicios-content.component.html",
  styleUrls: ["./nutri-exercicios-content.component.scss"],
})
export class NutriExerciciosContentComponent {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  options = {
    search: "",
    page: 1,
  };

  loading = false;
  apiSearch: Subscription;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public _alimentos: NutriAlimentosService,
    public _exercicios: NutriExerciciosService,
    public helpers: HelpersService
  ) {
    this._exercicios.getDay();
    this.doSearch();
  }
  ionViewWillEnter() {}

  exercicioInternaPage(item, atualiza?) {
    const quantidade = item.pivot ? item.pivot.quantidade : null;
    this.navCtrl.navigateForward("/nutri-exercicio-interna");
    this.pageParams.set("nutri-exercicio-interna", {
      item,
      atualiza,
      quantidade,
    });
  }

  doSearch() {
    this.loading = true;
    this.options.page = 1;
    this._exercicios._getPaginate = this._exercicios
      .getPaginate(this.options)
      .subscribe(
        (r: any) => {
          this.loading = false;
        },
        (e) => (this.loading = false)
      );
  }

  doInfinite() {
    this.loading = true;
    this.options.page++;
    this._exercicios._getPaginate = this._exercicios
      .getPaginate(this.options)
      .subscribe(
        (r: any) => {
          this.loading = false;
          this.infiniteScroll.complete();
          if (r.data.length === 0) {
            this.options.page--;
          }
        },
        (e) => {
          this.infiniteScroll.complete();
          this.loading = false;
        }
      );
  }
}
