import { AfterViewInit, Component } from "@angular/core";
import { NavController } from "@ionic/angular";
import { GlobalService } from "src/app/services/global.service";

/**
 * Generated class for the ConhecaPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
import { PageParamsService } from "src/app/services/page-params.service";
@Component({
  selector: "page-conheca",
  templateUrl: "conheca.html",
})
export class ConhecaPage implements AfterViewInit {
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public global: GlobalService
  ) {}

  ngAfterViewInit() {
    console.log("ngAfterViewInit ConhecaPage");
  }
}
