import { Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Plugins } from "@capacitor/core";
import { ToastController } from "@ionic/angular";
import * as moment from "moment";
import { GlobalService } from "./global.service";

/*
  Generated class for the HelpersService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class HelpersService {
  moment = moment;

  constructor(
    public sanitizer: DomSanitizer,
    public toastCtrl: ToastController,
    public global: GlobalService
  ) {
    this.moment.locale("pt-BR");
    console.log("Hello HelpersService Service");
  }

  formMarkAllTouched(form: NgForm) {
    (<any>Object).values(form.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        (<any>Object)
          .values(control.controls)
          .forEach((c) => this.formMarkAllTouched(c));
      }
    });
  }

  sanitizeStyle(style) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  isEmail(str) {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(String(str).toLowerCase());
  }

  removeHttp(str) {
    if (!str) {
      return "";
    }
    return str.replace("http://", "").replace("https://", "");
  }
  extToMime = {
    aac: "audio/aac",
    abw: "application/x-abiword",
    arc: "application/octet-stream",
    avi: "video/x-msvideo",
    azw: "application/vnd.amazon.ebook",
    bin: "application/octet-stream",
    bz: "application/x-bzip",
    bz2: "application/x-bzip2",
    csh: "application/x-csh",
    css: "text/css",
    csv: "text/csv",
    doc: "application/msword",
    eot: "application/vnd.ms-fontobject",
    epub: "application/epub+zip",
    gif: "image/gif",
    htm: "text/html",
    html: "text/html",
    ico: "image/x-icon",
    ics: "text/calendar",
    jar: "application/java-archive",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    js: "application/javascript",
    json: "application/json",
    mid: "audio/midi",
    midi: "audio/midi",
    mpeg: "video/mpeg",
    mpkg: "application/vnd.apple.installer+xml",
    odp: "application/vnd.oasis.opendocument.presentation",
    ods: "application/vnd.oasis.opendocument.spreadsheet",
    odt: "application/vnd.oasis.opendocument.text",
    oga: "audio/ogg",
    ogv: "video/ogg",
    ogx: "application/ogg",
    otf: "font/otf",
    png: "image/png",
    pdf: "application/pdf",
    ppt: "application/vnd.ms-powerpoint",
    rar: "application/x-rar-compressed",
    rtf: "application/rtf",
    sh: "application/x-sh",
    svg: "image/svg+xml",
    swf: "application/x-shockwave-flash",
    tar: "application/x-tar",
    tif: "image/tiff",
    tiff: "image/tiff",
    ts: "application/typescript",
    ttf: "font/ttf",
    vsd: "application/vnd.visio",
    wav: "audio/x-wav",
    weba: "audio/webm",
    webm: "video/webm",
    webp: "image/webp",
    woff: "font/woff",
    woff2: "font/woff2",
    xhtml: "application/xhtml+xml",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.ms-excel",
    xml: "application/xml",
    xul: "application/vnd.mozilla.xul+xml",
    zip: "application/zip",
    "3gp": "video/3gpp",
    "3g2": "video/3gpp2",
    "7z": "application/x-7z-compressed",
  };
  getMimeType(str) {
    let extension = str.split(".");
    extension = extension[extension.length - 1];
    return this.extToMime[extension];
  }

  async toast(message) {
    (
      await this.toastCtrl.create({ message: message, duration: 4000 })
    ).present();
  }

  isAppleReview() {
    // ele é review se informacoes.c1 está false
    return !this.global.informacoes.c2;
  }

  openLink(link, where) {
    Plugins.Browser.open({
      url: link,
    });
  }
}
