import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the ToFixedPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'toFixed',
})
export class ToFixedPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value, ...args) {
    args[0] = args[0] ? args[0] : 1; 
    value = parseFloat(value);
    if (!value) {
      return 0;
    }
    if (Number.isInteger(value)) {
      return value;
    }
    return value.toFixed(args[0]);
  }
}
