import { Component, ViewChild } from "@angular/core";
import { IonRefresher, NavController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { NoticiasService } from "src/app/services/noticias.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "page-central-noticias",
  templateUrl: "central-noticias.html",
})
export class CentralNoticiasPage {
  @ViewChild(IonRefresher) refresher: IonRefresher;
  area_id = null;

  constructor(
    public navCtrl: NavController,
    public api: ApiService,
    public loading: LoadingService,
    public aplicativos: AplicativosService,
    public _noticias: NoticiasService,
    public helpers: HelpersService,
    public pageParams: PageParamsService
  ) {
    this.onRefresher();
  }

  async onRefresher() {
    await this.loading.present();
    this._noticias.getAll().then(
      () => {
        this.loading.dismiss();
        this.refresher.complete();
      },
      () => {
        this.loading.dismiss();
        this.refresher.complete();
      }
    );
  }

  interna(item) {
    this.navCtrl.navigateForward("/central-noticias-interna");
    this.pageParams.set("central-noticias-interna", {
      item: item,
      parentPage: this,
    });
  }
}
