import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-book",
  template: `
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 297 217.2"
    >
      <g>
        <path
          fill="currentColor"
          d="M296.9,27.7l-13.5-4.6l0.1-11.6c0,0-29.8-11.5-67.7-11.5c-31.8,0-58.7,8.2-67.3,11.1C139.9,8.2,113.1,0,81.3,0
		C43.4,0,14.9,11.2,14.9,11.2L15,24.2l-15,4l0.1,189.4l10.1-4.3c0.4-0.1,34.3-14.2,65.5-14.2c26.8,0,58.2,7.9,67.6,10.5l6.3,1.8
		l5.9-1.9c8.5-2.6,36.3-10.4,62.6-10.4c31,0,68.6,14.2,69,14.3l9.9,3.8L296.9,27.7z M141.6,191c-13.5-3.5-39.1-9.3-62.3-9.3
		c-22.6,0-45.7,5.5-58.3,9.1V25.2c12.2-3.3,33.3-7.8,56.8-7.8c28.8,0,54.1,6.8,63.8,9.7V191z M276.1,191
		c-13.5-3.5-39.1-9.3-62.3-9.3c-21.7,0-43.7,5-56.6,8.6V27.1c9.5-2.9,34.4-9.7,62.9-9.7c23.2,0,43.8,4.5,55.9,7.7L276.1,191
		L276.1,191z M276.1,191"
        />
        <path
          fill="currentColor"
          d="M179.2,45.9c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4-42.5-4
		c-22.3,0-43.5,3.9-44.4,4L179.2,45.9z M179.2,45.9"
        />
        <path
          fill="currentColor"
          d="M262.9,61.7l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1c-22.3,0-43.5,3.9-44.4,4.1l1.5,5.7c0.2,0,21.4-3.9,42.9-3.9
		C243.5,57.8,262.7,61.6,262.9,61.7L262.9,61.7z M262.9,61.7"
        />
        <path
          fill="currentColor"
          d="M179.2,76.8c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1
		c-22.3,0-43.5,3.9-44.5,4.1L179.2,76.8z M179.2,76.8"
        />
        <path
          fill="currentColor"
          d="M222.1,88.7c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1c-22.3,0-43.5,3.9-44.5,4.1l1.5,5.6
		C179.4,92.5,200.6,88.7,222.1,88.7L222.1,88.7z M222.1,88.7"
        />
        <path
          fill="currentColor"
          d="M222.1,104c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.1-20.2-4-42.5-4c-22.3,0-43.5,3.9-44.5,4l1.5,5.7
		C179.4,107.9,200.6,104,222.1,104L222.1,104z M222.1,104"
        />
        <path
          fill="currentColor"
          d="M222.1,114c-22.3,0-43.5,3.9-44.5,4.1l1.5,5.6c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6
		C263.8,117.9,244.4,114,222.1,114L222.1,114z M222.1,114"
        />
        <path
          fill="currentColor"
          d="M177.7,133.1l1.5,5.6c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4-42.5-4
		C199.8,129,178.5,132.9,177.7,133.1L177.7,133.1z M177.7,133.1"
        />
        <path
          fill="currentColor"
          d="M177.7,148.1l1.5,5.7c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.1-20.2-4-42.5-4
		C199.8,144.1,178.5,147.9,177.7,148.1L177.7,148.1z M177.7,148.1"
        />
        <path
          fill="currentColor"
          d="M177.7,164.1l1.5,5.7c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1
		C199.8,160.1,178.5,164,177.7,164.1L177.7,164.1z M177.7,164.1"
        />
        <path
          fill="currentColor"
          d="M40,45.9c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4-42.5-4
		c-22.3,0-43.5,3.9-44.4,4L40,45.9z M40,45.9"
        />
        <path
          fill="currentColor"
          d="M123.7,61.7l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1c-22.3,0-43.5,3.9-44.4,4.1l1.5,5.7c0.2,0,21.4-3.9,42.9-3.9
		C104.3,57.8,123.5,61.6,123.7,61.7L123.7,61.7z M123.7,61.7"
        />
        <path
          fill="currentColor"
          d="M40,76.8c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1
		c-22.3,0-43.5,3.9-44.5,4.1L40,76.8z M40,76.8"
        />
        <path
          fill="currentColor"
          d="M82.9,88.7c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1c-22.3,0-43.5,3.9-44.5,4.1l1.5,5.6
		C40.2,92.5,61.4,88.7,82.9,88.7L82.9,88.7z M82.9,88.7"
        />
        <path
          fill="currentColor"
          d="M82.9,104c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.1-20.2-4-42.5-4c-22.3,0-43.5,3.9-44.5,4l1.5,5.7
		C40.2,107.9,61.4,104,82.9,104L82.9,104z M82.9,104"
        />
        <path
          fill="currentColor"
          d="M82.9,114c-22.3,0-43.5,3.9-44.5,4.1l1.5,5.6c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6
		C124.6,117.9,105.2,114,82.9,114L82.9,114z M82.9,114"
        />
        <path
          fill="currentColor"
          d="M38.5,133.1l1.5,5.6c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4-42.5-4
		C60.6,129,39.3,132.9,38.5,133.1L38.5,133.1z M38.5,133.1"
        />
        <path
          fill="currentColor"
          d="M38.5,148.1l1.5,5.7c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.1-20.2-4-42.5-4
		C60.6,144.1,39.3,147.9,38.5,148.1L38.5,148.1z M38.5,148.1"
        />
        <path
          fill="currentColor"
          d="M38.5,164.1l1.5,5.7c0.2,0,21.4-3.9,42.9-3.9c21.4,0,40.6,3.9,40.8,3.9l1.7-5.6c-0.8-0.2-20.2-4.1-42.5-4.1
		C60.6,160.1,39.3,164,38.5,164.1L38.5,164.1z M38.5,164.1"
        />
      </g>
    </svg>
  `,
})
export class IconBookComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
