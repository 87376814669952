import { Directive, HostListener, Input } from "@angular/core";
import { NavController } from "@ionic/angular";
import { PreviousRouteService } from "../services/previous-route.service";

@Directive({
  selector: "[navPop]",
})
export class NavPopDirective {
  @Input() defaultHref: string;
  constructor(
    public navCtrl: NavController,
    public previousRoute: PreviousRouteService
  ) {}

  @HostListener("click", ["$event"])
  onClick(ev) {
    console.log("hello default href", this.defaultHref);
    console.log("hello back route", this.previousRoute.getPreviousUrl());
    if (
      this.previousRoute.getPreviousUrl() &&
      this.previousRoute.getPreviousUrl() !== this.previousRoute.getCurrentUrl()
    ) {
      this.navCtrl.pop();
    } else if (this.defaultHref) {
      this.navCtrl.navigateBack(this.defaultHref);
    }
  }
}
