import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  NavController,
  ToastController,
  AlertController,
} from "@ionic/angular";
import { Subject } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { PdfsService } from "src/app/services/pdfs.service";

@Component({
  selector: "app-pdfs-e-dicas-content",
  templateUrl: "./pdfs-e-dicas-content.component.html",
  styleUrls: ["./pdfs-e-dicas-content.component.scss"],
})
export class PdfsEDicasContentComponent implements OnInit {
  active = this._pdfs.list.length ? "pdf" : "dicas";
  dicas: any = [];
  loading = false;

  requests = new Subject<number>();
  requestsCompleted = 0;
  @Output() onRequestEnd = new EventEmitter();
  constructor(
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public alertCtrl: AlertController,
    public _pdfs: PdfsService,
    public _aplicativos: AplicativosService
  ) {}
  ngOnInit(): void {
    this.requests.subscribe(() => {
      if (this.requestsCompleted == 2) {
        this.onRequestEnd.emit();
        this.loading = false;
        this.requestsCompleted = 0;
      }
    });

    this.getPdfsEDicas();
  }

  getPdfsEDicas() {
    if (!this.dicas.length || !this._pdfs.list.length) {
      this.loading = true;
    }
    this.api.get("/dicas?aplicativo_id=" + this._aplicativos.item.id).subscribe(
      (r: any) => {
        this.requests.next(this.requestsCompleted++);
        this.dicas = r;
      },
      (e) => this.requests.next(this.requestsCompleted++)
    );
    this._pdfs.getAll().subscribe(
      (r: any) => this.requests.next(this.requestsCompleted++),
      (e) => this.requests.next(this.requestsCompleted++)
    );
  }

  openTab(tab) {
    this.active = tab;
  }
  toggleDica(i) {
    this.dicas[i].active = !this.dicas[i].active;
  }
}
