import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

type MindTabs = "categorias" | "frase" | "home" | "favoritos" | "audios";
@Component({
  templateUrl: "easymind-tabs.html",
  styleUrls: ["easymind-tabs.scss"],
})
export class EasymindTabsPage implements AfterViewInit {
  headerTitle = "";
  tab: MindTabs = "home";
  queryParamsSub: Subscription;
  constructor(
    public _categorias: CategoriasService,
    public route: ActivatedRoute
  ) {}
  ngAfterViewInit() {
    // encontra todas conteudos
    this._categorias.getAll();
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.setTab(params.tab);
      } else {
        this.setTab("home");
      }
    });
  }
  ngOnDestroy() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }
  setTab(tab: MindTabs) {
    this.tab = tab;
    if (this.tab === "frase") {
      this.headerTitle = "Motive-se";
    }
    if (this.tab === "home") {
      this.headerTitle = "Home";
    }
    if (this.tab === "favoritos") {
      this.headerTitle = "Favoritos";
    }
    if (this.tab === "audios") {
      this.headerTitle = "Áudios";
    }
    if (this.tab === "categorias") {
      this.headerTitle = "Roteiros";
    }
  }
}
