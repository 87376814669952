import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AplicativosService } from "../services/aplicativos.service";

@Injectable({
  providedIn: "root",
})
export class InitialPageGuard implements CanActivate {
  constructor(public aplicativos: AplicativosService, public router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (
      state.url.includes(this.aplicativos.getInitialPage(this.aplicativos.item))
    ) {
      return true;
    }
    return this.router.parseUrl("/aplicativos");
  }
}
