import { Component, Input, OnInit } from '@angular/core';
import { CreditCardInterface } from '../credit-card/credit-card.component';

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
})
export class CreditCardFormComponent implements OnInit {
  @Input() cc: CreditCardInterface
  constructor() { }

  ngOnInit() {}

}
