import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AplicativosService } from "./aplicativos.service";

/*
  Generated class for the CategoriasService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class AreaService {
  list: any[] = [];

  current: any = {};
  constructor(
    public api: ApiService,
    public toastCtrl: ToastController,
    public aplicativos: AplicativosService
  ) {
    this.getAll();
  }

  get(url?) {
    if (!url) {
      url = "/area";
    }
    return this.api.get(url);
  }

  getAll() {
    return this.get("/area")
      .pipe(
        map((r: any) => {
          this.list = r;

          return this.list;
        })
      )
      .toPromise();
  }
}
