import { Component } from "@angular/core";

@Component({
  selector: "app-icon-exercise",
  template: `<svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 274 224"
    style="enable-background:new 0 0 274 224;"
    xml:space="preserve"
  >
    <path
      fill="currentColor"
      d="M10.7,77.7H24v21.4c0,6.9,5.6,12.4,12.4,12.4c2.2,0,4.1-0.6,5.9-1.6v6c0,6.9,5.6,12.4,12.4,12.4c6.9,0,12.4-5.6,12.4-12.4
 V77.7h14.4l12.7-13.4v-7.9h-27V18.1c0-6.9-5.6-12.4-12.4-12.4c-6.9,0-12.4,5.6-12.4,12.4v6c-1.8-1-3.8-1.6-5.9-1.6
 C29.6,22.5,24,28.1,24,35v21.4H10.7C4.8,56.4,0,61.2,0,67.1S4.8,77.7,10.7,77.7z"
    />
    <path
      fill="currentColor"
      d="M263.3,56.4h-16.5V35c0-6.9-5.6-12.4-12.4-12.4c-2.1,0-4.1,0.6-5.9,1.6v-6c0-6.9-5.6-12.4-12.4-12.4
 c-6.9,0-12.4,5.6-12.4,12.4v38.3h-4.5v21.3h4.5v38.3c0,6.9,5.6,12.4,12.4,12.4s12.4-5.6,12.4-12.4v-6c1.8,1,3.8,1.6,5.9,1.6
 c6.9,0,12.4-5.6,12.4-12.4V77.7h16.5c5.9,0,10.6-4.8,10.6-10.7S269.2,56.4,263.3,56.4z"
    />
    <path
      fill="currentColor"
      d="M113.3,15.6c-5.3,0-9.6,4.3-9.6,9.6V64L88.4,80.2c-7.1,7.5-7.8,19.8-1.7,28.1L98.4,124c4,5.5,7.6,14.8,7.6,20
 c0,8.9,8.6,16.1,19.1,16.1h37.6c10.5,0,19.1-8.6,19.1-19.1v-4.2c0-7,4.4-18.2,7-24.9c1.3-3.2,2.1-6.5,2.4-9.6
 c0.4-3.7-0.3-8.1-1.7-12.5c0-0.3,0.1-0.5,0.1-0.8V42.2c0-5.3-4.3-9.6-9.6-9.6c-5.3,0-9.6,4.3-9.6,9.6v26.3c-3.4-1.3-7.2-2-11.2-2.1
 c-7.9-0.2-15.7,1.5-23,5.3c-4.1,2.1-6.9,5.7-7.7,10.2c-1,5.3,0.8,11.2,4.9,15.7c0.6,0.7,1.2,1.4,1.8,2c-0.1,0-0.1-0.1-0.2-0.1
 c-6.3-3.8-13.7-9.5-17.9-17l12.4-16c1.6-2.1,5.1-3.8,7.7-3.8h21.9c5.3,0,9.6-4.3,9.6-9.6c0-3.2-1.6-6-4-7.7V31.1
 c0-5.3-4.3-9.6-9.6-9.6s-9.6,4.3-9.6,9.6v12.5h-1.7V31.1c0-5.3-4.3-9.6-9.6-9.6s-9.6,4.3-9.6,9.6v13.1c-0.7,0.2-1.3,0.5-1.9,0.9
 c0,0,0,0,0,0V25.1C122.9,19.9,118.6,15.6,113.3,15.6z"
    />
    <path
      fill="currentColor"
      d="M184.2,202v-19.1c0-7.9-6.4-14.3-14.3-14.3h-56.2c-7.9,0-14.3,6.4-14.3,14.3V202c0,7.9,6.4,14.3,14.3,14.3h56.2
 C177.8,216.3,184.2,209.9,184.2,202z"
    />
  </svg> `,
})
export class IconExerciseComponent {}
