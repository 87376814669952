import { Component } from "@angular/core";
import { NutriAguaService } from "src/app/services/nutri-agua.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { NutriExerciciosService } from "src/app/services/nutri-exercicios.service";
import { HelpersService } from "src/app/services/helpers.service";

/**
 * Generated class for the RadioWeekComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "radio-week",
  templateUrl: "radio-week.html",
  styleUrls: ["radio-week.scss"],
})
export class RadioWeekComponent {
  constructor(
    public _alimentos: NutriAlimentosService,
    public _agua: NutriAguaService,
    public _exercicios: NutriExerciciosService,
    public helpers: HelpersService
  ) {}

  getDay() {
    this._alimentos.getDay();
    this._agua.get().subscribe();
    this._exercicios.getDay();
  }

  change(day) {
    this._alimentos.selectedDate = day;
    console.log("clicou");
    this.getDay();
  }
}
