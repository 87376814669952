import { Component } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { NutriAguaService } from "src/app/services/nutri-agua.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { NutriExerciciosService } from "src/app/services/nutri-exercicios.service";
import { HelpersService } from "src/app/services/helpers.service";

@Component({
  selector: "home-tabbar",
  templateUrl: "home-tabbar.html",
  styleUrls: ["home-tabbar.scss"],
})
export class HomeTabbarComponent {
  constructor(
    public helpers: HelpersService,
    public auth: AuthService,
    public _agua: NutriAguaService,
    public _alimentos: NutriAlimentosService,
    public _exercicios: NutriExerciciosService
  ) {}

  titulo() {
    let semanas = this.helpers
      .moment()
      .diff(
        this.helpers.moment(
          this.auth.user.user.created_at,
          "YYYY-MM-DD HH:mm:ss"
        ),
        "weeks"
      );

    return (
      semanas +
      1 +
      "ª semana - " +
      this.helpers.moment().format("[hoje, ] DD [de] MMMM")
    );
  }

  getDay() {
    this._agua.get().subscribe();
    this._alimentos.getDay();
    this._exercicios.getDay();
  }
}
