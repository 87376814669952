/**
 * Generated class for the PerfilPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

import { Component } from "@angular/core";

@Component({
  selector: "page-perfil",
  templateUrl: "perfil.html",
})
export class PerfilPage {}
