import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AplicativosService } from "./aplicativos.service";
import { AuthService } from "./auth.service";

/*
  Generated class for the CategoriasService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class NotificacoesService {
  list: any = [];
  current: any = {};
  _getAll: Subscription;
  constructor(
    public api: ApiService,
    public toastCtrl: ToastController,
    public aplicativos: AplicativosService,
    public auth: AuthService
  ) {
    console.log("Hello NotificacoesService Service");
  }

  get(url?) {
    if (!url) {
      url = "/users/" + this.auth.user.user.id + "/notifications";
    }
    return this.api.get(url);
  }

  getAll(area = null) {
    const opened = this.list.find((consultas) => consultas.open);

    return new Promise((resolve, reject) => {
      if (this._getAll && !this._getAll.closed) {
        this._getAll.unsubscribe();
      }

      this._getAll = this.get(
        "/users/" + this.auth.user.user.id + "/notifications"
      )
        .pipe(
          map((r: any) => {
            this.list = r;

            return this.list;
          })
        )
        .subscribe(
          (r: any) => resolve(r),
          (e) => reject(e)
        );
    });
  }
}
