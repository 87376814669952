import { Component, OnInit, ViewChild } from "@angular/core";
import { IonRefresher, NavController } from "@ionic/angular";
import { CategoriasService } from "src/app/services/categorias.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-categorias-mind",
  templateUrl: "./categorias-mind.component.html",
  styleUrls: ["./categorias-mind.component.scss"],
})
export class CategoriasMindComponent {
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _categorias: CategoriasService,
    public loading: LoadingService
  ) {}

  ionViewDidEnter() {
    this._categorias.getAll();
  }

  openRoteiro(index) {
    this._categorias.closeAll();
    this.navCtrl
      .navigateForward("/easymind-tabs?tab=categorias")
      .then(() => this._categorias.openItem(index));
  }
}
