import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { IonRefresher, IonContent, NavController } from "@ionic/angular";
import { CategoriasService } from "src/app/services/categorias.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-categorias-lista",
  templateUrl: "./categorias-lista.component.html",
  styleUrls: ["./categorias-lista.component.scss"],
})
export class CategoriasListaComponent {
  @ViewChild(IonRefresher) refresher: IonRefresher;
  @ViewChildren("itemsRef") itemsRef: QueryList<ElementRef>;

  loading = false;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _categorias: CategoriasService
  ) {
    if (this._categorias.list.length == 0) {
      this.loading = true;
      this._categorias.getAll().finally(() => {
        this.loading = false;
      });
    }
  }
}
