import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-hand",
  templateUrl: "icon-hand.component.svg",
})
export class IconHandComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
