import { Injectable } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { Platform, AlertController, ToastController } from "@ionic/angular";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AplicativosService } from "./aplicativos.service";

@Injectable({
  providedIn: "root",
})
export class PdfsService {
  list: any = [];

  constructor(
    public api: ApiService,
    public plt: Platform,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public _aplicativos: AplicativosService
  ) {
  }

  getAll() {
    return this.api
      .get("/pdfs?aplicativo_id=" + this._aplicativos.item.id)
      .pipe(map((r: any) => (this.list = r)));
  }
  getAllIfNecessary() {
    // if(this.list.length == 0) {
    this.getAll().subscribe();
    // }
  }

  async open(pdf) {
    if (!pdf.thumbnail_principal) {
      (
        await this.alertCtrl.create({
          header: "Oops",
          message:
            "Não foi possível carregar, por favor tente novamente mais tarde",
        })
      ).present();
      return;
    }

    if (this.plt.is("ios")) {
      Plugins.Browser.open({
        url: pdf.thumbnail_principal,
      });
    } else {
      // this.iap.create(pdf.thumbnail_principal, '_system', options).show();
      Plugins.Browser.open({
        url: pdf.thumbnail_principal,
        windowName: "_system",
      });
    }

    this.ler(pdf.id).subscribe();
  }

  ler(pdf_id) {
    this.list = this.list.map((pdf) => {
      if (pdf.id == pdf_id) {
        pdf.lido = 1;
      }
      return pdf;
    });
    return this.api.patch("/pdfs/" + pdf_id + "/ler");
  }

  favoritar(pdf) {
    console.log(`favoritar`, pdf);
    return new Promise((resolve) => {
      if (pdf.favoritado != 1) {
        this.patchFavoritar(pdf.id).subscribe(
          async () => {
            (
              await this.toastCtrl.create({
                message: pdf.titulo + " adicionado aos seus favoritos ",
                duration: 3000,
              })
            ).present();
            resolve(true);
          },
          (e) => resolve(true)
        );
      } else {
        this.deleteFavoritar(pdf.id).subscribe(
          async () => {
            (
              await this.toastCtrl.create({
                message: pdf.titulo + " removido de seus favoritos ",
                duration: 3000,
              })
            ).present();
            resolve(true);
          },
          (e) => resolve(true)
        );
      }
    });
  }
  patchFavoritar(pdf_id) {
    this.list = this.list.map((pdf) => {
      if (pdf.id == pdf_id) {
        pdf.favoritado = 1;
      }
      return pdf;
    });
    return this.api.patch("/pdfs/" + pdf_id + "/favoritar");
  }

  deleteFavoritar(pdf_id) {
    this.list = this.list.map((conteudo) => {
      if (conteudo.id == pdf_id) {
        conteudo.favoritado = 0;
      }
      return conteudo;
    });
    return this.api.delete("/pdfs/" + pdf_id + "/favoritar");
  }
}
