import { Component, ViewChild } from "@angular/core";
import { IonRefresher, NavController, ToastController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AreaService } from "src/app/services/area.service";
import { AuthService } from "src/app/services/auth.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { CentralNoticiasPage } from "../central-noticias/central-noticias";

@Component({
  selector: "page-central-noticias-interna",
  templateUrl: "central-noticias-interna.html",
})
export class CentralNoticiasInternaPage {
  subareas: any = [];
  noticia: any = {
    titulo: "",
    data: "",
    texto: "",
    thumbnail_principal: "",
  };

  parentPage: CentralNoticiasPage;

  @ViewChild(IonRefresher) refresher: IonRefresher;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _areas: AreaService,
    public api: ApiService,
    public loading: LoadingService,
    public aplicativos: AplicativosService,
    public toastCtrl: ToastController,
    public auth: AuthService,
    public helpers: HelpersService
  ) {
    if (!this._areas.list.length) {
      this.loading.present();
    }
    this.onRefresher();

    if (this.pageParams.get("central-noticias-interna")) {
      this.noticia = this.pageParams.get("central-noticias-interna");
    }
  }

  onRefresher() {
    this.ionViewDidEnter();
  }

  ionViewDidEnter() {}
}
