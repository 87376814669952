import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertController, ToastController } from "@ionic/angular";
import { CreditCardInterface } from "src/app/components/credit-card/credit-card.component";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PlanosService } from "src/app/services/planos.service";

@Component({
  selector: "page-contratar-pagar",
  templateUrl: "contratar-pagar.html",
  styleUrls: ["contratar-pagar.scss"],
})
export class ContratarPagar {
  @ViewChild(NgForm) form: NgForm;
  modalidade: "mensal" | "anual" = "mensal";
  cc: CreditCardInterface = {
    name: "",
    number: "",
    month: "",
    year: "",
    cvv: "",
  };
  cupom: any;
  alertCupom: HTMLIonAlertElement;
  constructor(
    public planosService: PlanosService,
    public aplicativosService: AplicativosService,
    public helpersService: HelpersService,
    public apiService: ApiService,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public loadingService: LoadingService,
    public router: Router
  ) {
    this.aplicativosService.clearItem();
    this.aplicativosService.clearTheme();
  }
  ionViewWillEnter() {
    this.tenhoUmCupom();
  }
  ionViewWillLeave() {
    if (this.alertCupom) {
      this.alertCupom.dismiss();
    }
  }
  async tenhoUmCupom() {
    this.alertCupom = await this.alertCtrl.create({
      header: "Você tem um cupom?",
      message: "Insira aqui seu cupom para obter descontos incríveis",
      inputs: [
        {
          name: "cupom",
          placeholder: "SEU CUPOM",
        },
      ],
      buttons: [
        {
          role: "cancel",
          text: "Não tenho",
        },
        {
          text: "Validar",
          handler: (inputs) => {
            if (inputs.cupom) {
              this.verificarCupom(inputs.cupom);
            }
          },
        },
      ],
    });
    this.alertCupom.present();
  }
  async verificarCupom(cupom) {
    try {
      const response = await this.apiService
        .get("/cupom/" + cupom.split(" ").join(""))
        .toPromise<any>();
      this.cupom = response.data;
      this.helpersService.toast("Cupom aplicado!");
    } catch (e) {
      this.cupom = null;
    }
  }
  async onSubmit() {
    if (!this.isCcValid()) {
      (
        await this.toastCtrl.create({
          message:
            "Verifique se todos os campos estão preenchidos corretamente",
          duration: 3000,
        })
      ).present();
      return;
    }
    const body: any = {
      plano_id: this.planosService.currentPlano.id,
      cc: { ...this.cc },
      tipo: this.modalidade,
    };
    if (this.cupom) {
      body.cupom_codigo = this.cupom.codigo;
    }
    if (this.modalidade === "anual") {
      body.parcelas = 12;
    }
    await this.loadingService.present();
    try {
      const response = await this.apiService
        .post("/pedido", body)
        .toPromise<any>();
      if (response.message) {
        this.helpersService.toast("Parabéns, plano adquirido!");
      }
      this.router.navigate(["/aplicativos"]);
    } finally {
      this.loadingService.dismiss();
    }
  }

  isCcValid() {
    if (this.cc.cvv?.length < 3) {
      return false;
    }
    if (this.cc.month?.length < 2) {
      return false;
    }
    if (this.cc.year?.length < 2) {
      return false;
    }
    if (this.cc.name?.split(" ").length < 2) {
      return false;
    }
    if (this.cc.number?.split(" ").join("").length < 16) {
      return false;
    }
    return true;
  }

  getValorAnual(cupom = false) {
    if (cupom && this.cupom) {
      return (
        this.getValorAnual() -
        this.getValorAnual() * (this.cupom.desconto / 100)
      );
    }
    return this.planosService.currentPlano.valor_anual_corrigido / 12;
  }

  getValorMensal(cupom = false) {
    if (cupom && this.cupom) {
      return (
        this.getValorMensal() -
        this.getValorMensal() * (this.cupom.desconto / 100)
      );
    }
    return this.planosService.currentPlano.valor_mensal;
  }
}
