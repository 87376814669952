/**
 * Generated class for the CalculoCaloricoPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
import { Component } from "@angular/core";
@Component({
  selector: "page-nutri-calculo-calorico",
  template: `
    <ion-header>
      <header title="CÁLCULO DE CALORIAS"></header>
    </ion-header>

    <ion-content>
      <app-nutri-calculo-calorico-content></app-nutri-calculo-calorico-content>
    </ion-content>
  `,
})
export class NutriCalculoCaloricoPage {}
