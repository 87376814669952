import { Injectable } from "@angular/core";

interface Params {
  [x: string]: any;
}

/**
 * You should store the instance to get inside the component to be used when going back or forward, use getLastInstance to get the number of the instance you want to use
 */

@Injectable({
  providedIn: "root",
})
export class PageParamsService {
  protected params: Params = {};

  constructor() {
    try {
      const paramsStorage = JSON.parse(
        window.localStorage.getItem("page_params_params")
      );
      if (paramsStorage) {
        this.params = paramsStorage;
      } else {
        this.params = {};
      }
    } catch {
      this.params = {};
    }
  }

  set(route: string, payload: any): void {
    this.params[route] = payload;
    this.saveStorage();
  }
  get(route: string, object?: string): any {
    if (object) {
      return this.params[route] ? this.params[route][object] : undefined;
    }
    return this.params[route];
  }
  clear(route: string) {
    delete this.params[route];
    this.saveStorage();
  }
  saveStorage() {
    window.localStorage.setItem(
      "page_params_params",
      JSON.stringify(this.params)
    );
  }
}
