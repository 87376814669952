import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component } from "@angular/core";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import {
  AlertController, NavController,
  ToastController
} from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "page-login-codigo",
  templateUrl: "login-codigo.html",
})
export class LoginCodigoPage implements AfterViewInit {
  obj = {
    identificador: "",
    code: "",
  };
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public auth: AuthService,
    public global: GlobalService,
    public toastCtrl: ToastController,
    public loading: LoadingService,
    public http: HttpClient,
    public helpers: HelpersService,
    public alertCtrl: AlertController,
    public statusBar: StatusBar
  ) {
    this.statusBar.backgroundColorByHexString("#000000");
    if (this.helpers.isAppleReview()) {
      this.navCtrl.navigateForward("/aplicativos");
    }
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit LoginCodigoPage");
    const obj = window.localStorage.getItem("login-codigo");
    if (obj) {
      this.obj = JSON.parse(obj);
    }
  }

  async onSubmit(form) {
    if (form.invalid) {
      (
        await this.toastCtrl.create({
          message: "Preencha todos os campos",
          duration: 2000,
        })
      ).present();
      return;
    }

    await this.loading.present();
    window.localStorage.setItem("login-codigo", JSON.stringify(this.obj));
    this.http
      .post(
        this.global.apiUrl + "/users/" + this.auth.user.user.id + "/premium",
        this.obj,
        {
          headers: this.auth.getAuthorizationHeader(),
        }
      )
      .subscribe(
        async (r: any) => {
          this.auth.setToken(r.token);
          this.loading.dismiss();
          this.navCtrl.navigateRoot("/aplicativos");
        },
        async (e) => {
          if (e.status == 400) {
            (
              await this.alertCtrl.create({
                header: "Oops...",
                message: e.error.message,
              })
            ).present();
          }
          if (e.status == 401) {
            (
              await this.alertCtrl.create({
                header: "Sessão Expirada",
                message:
                  "Você precisa logar novamente para continuar usando o aplicativo",
                cssClass: "text-danger",
                buttons: [
                  {
                    text: "OK",
                    handler: () => {
                      this.navCtrl
                        .navigateRoot("/login")
                        .then(() => this.auth.logout());
                    },
                    role: "cancel",
                  },
                ],
              })
            ).present();
          }
          this.loading.dismiss();
        }
      );
  }
  async logout() {
    this.navCtrl.navigateRoot("/login").then(() => this.auth.logout());
  }
}
