import { HttpProgressEvent } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { IonRefresher, NavController, ToastController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AreaService } from "src/app/services/area.service";
import { AuthService } from "src/app/services/auth.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-gestao-cadastro",
  templateUrl: "gestao-cadastro.component.html",
  styleUrls: ["gestao-cadastro.component.scss"],
})
export class GestaoCadastroComponent implements AfterViewInit {
  subareas: any = [];
  consulta: any = {
    area_id: "",
    subarea_id: "",
    nome: "",
    telefone: "",
    data_consulta: "",
    observacoes: "",
    tem_revisao: false,
    data_revisao: "",
    thumbnail_principal: "",
  };

  file: File = null;
  imgSrc: any = "";

  minDate: string = new Date().toISOString();

  @ViewChild("file") inputFile: ElementRef;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _areas: AreaService,
    public api: ApiService,
    public loading: LoadingService,
    public aplicativos: AplicativosService,
    public toastCtrl: ToastController,
    public auth: AuthService
  ) {}

  async ngAfterViewInit() {
    if (!this._areas.list.length) {
      await this.loading.present();
    }
    this._areas.getAll().finally(() => {
      this.loading.dismiss();
      if (this.pageParams.get("gestao-cadastro", "item")) {
        this.consulta = this.pageParams.get("gestao-cadastro", "item");
        this.imgSrc = this.consulta.thumbnail_principal
          ? this.consulta.thumbnail_principal
          : "";
        this.doSearch(this.consulta.area_id);
        this.pageParams.clear("gestao-cadastro");
      }
    });
  }

  doSearch(e) {
    let area = this._areas.list.find((area) => area.id == e);
    this.subareas = area.subareas;
  }

  async onSubmit(form: NgForm) {
    if (!form.valid) {
      (
        await this.toastCtrl.create({
          message: "Verifique se todos os campos estão preenchidos",
          duration: 4000,
        })
      ).present();
      return;
    }
    await this.loading.present();
    this.api
      .post("/users/" + this.auth.user.user.id + "/consultas", this.consulta)
      .subscribe(
        async (r: any) => {
          this.loading.dismiss();
          (
            await this.toastCtrl.create({
              message: "Sua consulta foi cadastrada com sucesso!",
              duration: 4000,
            })
          ).present();
          this.consulta = {
            area_id: "",
            subarea_id: "",
            nome: "",
            telefone: "",
            data_consulta: "",
            observacoes: "",
            tem_revisao: "",
            data_revisao: "",
            thumbnail_principal: "",
          };

          this.imgSrc = "";
          this.navCtrl.navigateForward("/gestao-tabs", {
            queryParams: { tab: "listagem" },
          });
        },
        (e) => this.loading.dismiss()
      );
  }

  onChangeInputFiles(files: FileList) {
    this.file = files.item(0);
    this.submitImagens(
      "/users/" + this.auth.user.user.id + "/imagemConsulta"
    ).then((e: HttpProgressEvent | any) => {
      this.consulta.thumbnail_principal = e.body.thumbnail_principal;
      this.imgSrc = e.body.preview;
    });
  }

  submitImagens(url) {
    return new Promise((resolve) => {
      this.loading.present();
      this.api.postFile(this.file, url).subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.loading.dismiss();
            resolve(event);
          }
        },
        (err) => this.loading.dismiss()
      );
    });
  }
}
