import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { NavController } from "@ionic/angular";
import { CategoriasService } from "src/app/services/categorias.service";
import { GlobalService } from "src/app/services/global.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-categorias-conteudos-content",
  templateUrl: "./categorias-conteudos-content.component.html",
  styleUrls: ["./categorias-conteudos-content.component.scss"],
})
export class CategoriasConteudosContentComponent implements AfterViewInit {
  loading = false;
  @Output() onRequestEnd = new EventEmitter();
  constructor(
    public global: GlobalService,
    public _categorias: CategoriasService,
    public navCtrl: NavController,
    public pageParams: PageParamsService
  ) {}

  ngAfterViewInit() {
    if (!this._categorias.list[0]?.id) {
      this._categorias
        .getAll({ completo: true })
        .finally(() => this.getConteudos());
    } else {
      this.getConteudos();
    }
  }

  getConteudos() {
    if (!this._categorias.current?.id) {
      this._categorias.current = this._categorias.list[0];
    }
    this.loading = true;
    return this._categorias
      .getTopicos(this._categorias.current.id)
      .then((r: any) => {
        this._categorias.current.topicos = r;
      })
      .finally(() => {
        this.loading = false;
        this.onRequestEnd.emit();
      });
  }

  certificadosPagarPage() {
    this.navCtrl.navigateForward("/certificados-pagar");
    this.pageParams.set("certificados-pagar", {
      categoria: Object.assign({}, this._categorias.current),
    });
  }
}
