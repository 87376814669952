export class Alimento {
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this, obj);
        this.energia = this.energia ? parseInt(this.energia) : 0;
        this.gordura = this.gordura ? parseFloat(this.gordura) : 0;
        this.carboidratos = this.carboidratos ? parseFloat(this.carboidratos) : 0;
        this.proteina = this.proteina ? parseFloat(this.proteina) : 0;

        if (this.pivot) {
            this.pivot.energia = Math.ceil((this.energia * this.pivot.quantidade) / 100);
            this.pivot.gordura = parseFloat(((this.gordura * this.pivot.quantidade) / 100).toFixed(2));
            this.pivot.carboidratos = parseFloat(((this.carboidratos * this.pivot.quantidade) / 100).toFixed(2));
            this.pivot.proteina = parseFloat(((this.proteina * this.pivot.quantidade) / 100).toFixed(2));
        }
    }
}