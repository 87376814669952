import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController, AlertController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Alimento } from "../models/alimento";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { HelpersService } from "./helpers.service";

/*
  Generated class for the NutriAlimentosService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class NutriAlimentosService {
  selectedDate = this.helpers.moment().format("YYYY-MM-DD");
  weekDays = new Array<any>();
  weekDaysFormated = new Array<any>();

  tipos = ["", "CAFÉ DA MANHÃ", "ALMOÇO", "JANTA", "LANCHES"];

  // tipo selecionado
  tipo = 1;

  loadingPaginate = false;
  listPaginate = new Array<Alimento>();
  _getPaginate: Subscription;

  loadingDestaques = false;
  destaques = new Array<Alimento>();
  _getDestaqeus: Subscription;

  day: {
    _get?: Subscription;
    list?: Array<Array<Alimento>>;
    loading?: boolean;
    restanteKcal?: number;
    consumidoKcal?: number;
    consumidoCarboidratos?: number;
    consumidoProteina?: number;
    consumidoGordura?: number;
  };

  constructor(
    public api: ApiService,
    public toastCtrl: ToastController,
    public helpers: HelpersService,
    public auth: AuthService,
    public alertCtrl: AlertController
  ) {
    if (!this.day) {
      this.day = {};
    }
    this.clearDay();

    console.log("Hello GlobalService Service");

    const startOfWeek = this.helpers.moment().startOf("isoWeek");
    const endOfWeek = this.helpers.moment().endOf("isoWeek");

    let day = startOfWeek;

    while (day <= endOfWeek) {
      this.weekDays.push(day);
      this.weekDaysFormated.push(day.format("YYYY-MM-DD"));
      day = day.clone().add(1, "d");
    }
    console.log(this.weekDays);
  }

  getDestaques() {
    if (this._getDestaqeus && !this._getDestaqeus.closed) {
      this._getDestaqeus.unsubscribe();
    }
    this.loadingDestaques = true;
    return this.api.get("/alimentos?destaque=1").pipe(
      map((r: any) => {
        this.destaques = r.map((obj) => new Alimento(obj));
        this.loadingDestaques = false;
        return this.destaques;
      })
    );
  }

  getPaginate(options: { page; favoritado; search }, load = false) {
    if (this._getPaginate && !this._getPaginate.closed) {
      this._getPaginate.unsubscribe();
    }
    this.loadingPaginate = load;
    return this.api
      .get(
        "/alimentos?page=" +
          options.page +
          "&search=" +
          options.search +
          "&favoritado=" +
          options.favoritado
      )
      .pipe(
        map((r: any) => {
          this.loadingPaginate = false;
          const array = r.data.map((obj) => new Alimento(obj));
          this.listPaginate =
            options.page === 1 ? array : this.listPaginate.concat(array);
          return r;
        })
      );
  }

  getDay(clear = true) {
    return new Promise((resolve) => {
      if (this.day._get && !this.day._get.closed) {
        this.day._get.unsubscribe();
      }
      if (clear) {
        this.clearDay();
      }
      this.day.loading = true;
      this.day._get = this.api
        .get(
          "/users/" +
            this.auth.user.user.id +
            "/alimentos?data=" +
            this.selectedDate
        )
        .subscribe(
          (r: any) => {
            this.clearDay();
            this.day.loading = false;
            r = r.map((obj) => new Alimento(obj));

            this.day.consumidoKcal = 0;

            // Objetivo separar o array list pelos tipos
            this.tipos.forEach((tipo, i) => {
              r.forEach((obj) => {
                if (i == obj.pivot.tipo) {
                  this.day.list[i].push(obj);
                }
              });
            });

            console.log(this.day.list);

            r.forEach((obj) => {
              console.log(obj);
              this.day.consumidoKcal += obj.pivot.energia;
              this.day.consumidoProteina += obj.pivot.proteina;
              this.day.consumidoCarboidratos += obj.pivot.carboidratos;
              this.day.consumidoGordura += obj.pivot.gordura;
            });

            this.day.restanteKcal =
              this.auth.user.user.objetivo_kcal - this.day.consumidoKcal;

            // nao  mostra numero negativo
            this.day.restanteKcal =
              this.day.restanteKcal < 0 ? 0 : this.day.restanteKcal;
            resolve(true);
          },
          (e) => {
            this.day.loading = false;
            resolve(true);
          }
        );
    });
  }

  clearDay() {
    if (!this.day) {
      this.day = {};
    }
    this.day.restanteKcal = this.auth.user.user.objetivo_kcal;
    this.day.consumidoKcal = 0;
    this.day.consumidoProteina = 0;
    this.day.consumidoCarboidratos = 0;
    this.day.consumidoGordura = 0;

    this.day.list = [];
    // Inicializa a array list para cada tipo, para q quando facamos o request nao de undefined index no momento do .push
    this.tipos.forEach((tipo, i) => (this.day.list[i] = new Array<Alimento>()));
  }

  favoritar(alimento) {
    console.log(`favoritar`, alimento);
    return new Promise((resolve) => {
      if (alimento.favoritado != 1) {
        this.patchFavoritar(alimento.id).subscribe(async () => {
          (
            await this.toastCtrl.create({
              message: alimento.titulo + " adicionado aos seus favoritos ",
              duration: 3000,
            })
          ).present();
          alimento.favoritado = 1;
          resolve(alimento);
        });
      } else {
        this.deleteFavoritar(alimento.id).subscribe(async () => {
          (
            await this.toastCtrl.create({
              message: alimento.titulo + " removido de seus favoritos ",
              duration: 3000,
            })
          ).present();
          alimento.favoritado = 0;
          resolve(alimento);
        });
      }
    });
  }

  patchFavoritar(alimento_id) {
    this.listPaginate = this.listPaginate.map((alimento) => {
      if (alimento.id == alimento_id) {
        alimento.favoritado = 1;
      }
      return alimento;
    });
    return this.api.patch("/alimentos/" + alimento_id + "/favoritar");
  }

  deleteFavoritar(alimento_id) {
    this.listPaginate = this.listPaginate.map((alimento) => {
      if (alimento.id == alimento_id) {
        alimento.favoritado = 0;
      }
      return alimento;
    });
    return this.api.delete("/alimentos/" + alimento_id + "/favoritar");
  }

  async delete(item, tipo) {
    (
      await this.alertCtrl.create({
        message: "Você tem certeza que deseja deletar esse alimento?",
        buttons: [
          {
            text: "Não",
            role: "cancel",
          },
          {
            text: "Sim",
            handler: () => {
              this.day.list[tipo] = this.day.list[tipo].filter(
                (obj) => obj.id != item.id
              );
              this.api
                .delete(
                  "/users/" + this.auth.user.user.id + "/alimentos/" + item.id,
                  { data: item.pivot.data, tipo: item.pivot.tipo }
                )
                .subscribe();
              this.getDay(false);
            },
          },
        ],
      })
    ).present();
  }
}
