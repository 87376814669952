import { Component, AfterViewInit, ViewChild } from "@angular/core";
import { IonRefresher, NavController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { NotificacoesService } from "src/app/services/notificacoes.service";
import { PageParamsService } from "src/app/services/page-params.service";
@Component({
  selector: "page-central-notificacoes",
  templateUrl: "central-notificacoes.html",
})
export class CentralNotificacoesPage {
  @ViewChild(IonRefresher) refresher: IonRefresher;
  area_id = null;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public loading: LoadingService,
    public aplicativos: AplicativosService,
    public _notificacoes: NotificacoesService,
    public helpers: HelpersService
  ) {
    this.onRefresher();
  }

  async onRefresher() {
    await this.loading.present();
    this._notificacoes.getAll().then(
      () => {
        this.loading.dismiss();
        this.refresher.complete();
      },
      () => {
        this.loading.dismiss();
        this.refresher.complete();
      }
    );
  }
}
