import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IonContent, NavController } from "@ionic/angular";
import { NutriAguaService } from "src/app/services/nutri-agua.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { NutriExerciciosService } from "src/app/services/nutri-exercicios.service";
import { HelpersService } from "src/app/services/helpers.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-nutri-calculo-calorico-content",
  templateUrl: "./nutri-calculo-calorico-content.component.html",
  styleUrls: ["./nutri-calculo-calorico-content.component.scss"],
})
export class NutriCalculoCaloricoContentComponent implements AfterViewInit {
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _alimentos: NutriAlimentosService,
    public helpers: HelpersService,
    public _exercicios: NutriExerciciosService,
    public _agua: NutriAguaService
  ) {}

  async ngAfterViewInit() {}

  getDay() {
    this._agua.get().subscribe();
    this._alimentos.getDay();
    this._exercicios.getDay();
  }
}
