import { Injectable } from "@angular/core";
import { CategoriasService } from "./categorias.service";

@Injectable({
  providedIn: "root",
})
export class CleanupService {
  constructor(public _categorias: CategoriasService) {}

  execute() {
    // clear data for new app
    this._categorias.current = {};
    this._categorias.list = [];
  }
}
