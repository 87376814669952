/**
 * Generated class for the FavoritosPage page.
 *
 * See https://ionicframework.com/docsodel/components/#navigation for more info on
 * Ionic pages and navigation.
 */

import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
import { IonRefresher } from "@ionic/angular";
import { Subscription } from "rxjs";
import { FavoritosContentComponent } from "src/app/components/favoritos-content/favoritos-content.component";


@Component({
  selector: "page-favoritos",
  templateUrl: "favoritos.html",
})
export class FavoritosPage implements OnDestroy, AfterViewInit {
  @ViewChild(IonRefresher) refresher: IonRefresher;
  @ViewChild(FavoritosContentComponent)
  favoritosContentComponent: FavoritosContentComponent;

  subscription: Subscription;
  constructor() {}
  ngAfterViewInit() {
    this.subscription = this.favoritosContentComponent.onRequestEnd.subscribe(
      () => {
        this.refresher.complete();
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRefresher() {
    this.favoritosContentComponent.getFavoritos();
  }
}
