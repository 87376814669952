import { Component, Input, EventEmitter, Output, HostBinding } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NavController, AlertController, Platform } from "@ionic/angular";
import { AuthService } from "src/app/services/auth.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PlayerService } from "src/app/services/player.service";

/**
 * Generated class for the SublistItemComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "sublist-item",
  templateUrl: "sublist-item.html",
  styleUrls: ["sublist-item.scss"],
})
export class SublistItemComponent {
  @Output("favoritar") favoritarEvent = new EventEmitter<any>();
  @Input() item: any = {};
  @Input() forcarFavorito = false;

  constructor(
    public _categorias: CategoriasService,
    public navCtrl: NavController,
    public auth: AuthService,
    public alert: AlertController,
    public global: GlobalService,
    public helpers: HelpersService,
    public plt: Platform,
    public player: PlayerService,
    public sanitizer: DomSanitizer,
    public loading: LoadingService
  ) {}

  playerPage() {
    this.player.playerPage(this.item);
  }

  @HostBinding('class.disabled') get disabled (){
    return !this.item.pode_ver
  }

  async descricao() {
    (
      await this.alert.create({
        header: this.item.titulo,
        message: this.item.descricao.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        cssClass: "alert-lg",
        buttons: [
          {
            role: "cancel",
            text: "Ok",
          },
        ],
      })
    ).present();
  }

  favoritar() {
    this._categorias.favoritar(this.item).then(() => {
      this.favoritarEvent.next();
    });
    if (this.forcarFavorito) {
      this.item.favoritado = this.item.favoritado ? 0 : 1;
    }
  }
}
