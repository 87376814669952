import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the CadastroService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class CadastroService {

  dados: any = {
    genero: '',
    data_nascimento: '',
    altura: '',
    inicio_kg: '',
    objetivo_descricao: '',
    objetivo_kg: '',
    objetivo_kcal: '',
    first_name: '',
    telefone: '',
    email: '',
    password: '',
    confirmPassword: ''
  }

  constructor(
    public http: HttpClient
  ) {
    console.log('Hello CadastroService Service');
  }

  clear() {
    this.dados = {
      genero: '',
      data_nascimento: '',
      altura: '',
      inicio_kg: '',
      objetivo_descricao: '',
      objetivo_kg: '',
      objetivo_kcal: '',
      first_name: '',
      telefone: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  }

}
