import { Component } from "@angular/core";


@Component({
  selector: 'app-icon-quotes',
  template: `
  <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 61.5 53.9" style="enable-background:new 0 0 61.5 53.9;" xml:space="preserve">

  <g>
    <path fill="currentColor" d="M16.2,0.4c8.4,2.5,12.9,10.8,11.5,21.2c-1.9,14.6-8.2,26.2-22.6,32c-6.2,1.8-4.9-2.7-5-6
      c4.5-2.1,9.3-3.5,12.4-7.7c1.7-2.3,3.1-4.6,3.3-7.6c0.3-3.6-0.8-6-4.8-6.7c-5.6-1-9.7-4-10.9-9.9c0-2.3,0-4.7,0-7
      C1.7,2.2,3.4,0.7,9.7,0C11.9,0.2,14.1-0.4,16.2,0.4z"/>
    <path fill="currentColor" d="M34.1,53.5c-0.4-3.7,0.5-5.6,4.8-6.7c7.4-1.9,11.4-7.8,12.8-15.1c0.9-4.3-1.1-6.5-6.3-7.4
      c-6.4-1.2-10.5-5.2-11.1-11c-0.6-5.7,2.7-10.6,8.7-12.9c1.9-0.7,3.9-0.2,5.8-0.3c9,0.1,12.3,3.7,12.8,13.8c0,3.3,0,6.6,0,9.9
      c-0.1,15-8.8,26.5-22.5,29.8C37.4,53.5,35.7,53.5,34.1,53.5z"/>
  </g>
  </svg>
  `
})
export class IconQuotesComponent {
  
} 