import { Injectable } from "@angular/core";
import { FileUploadOptions } from "@ionic-native/file-transfer/ngx";
import {
  LoadingController,
  AlertController,
  ToastController,
  Platform,
} from "@ionic/angular";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class UploadsService {
  multiInfo = {
    files: new Array<string>(),
    toSend: 0,
    sent: 0,
    failed: new Array<string>(),
    url: "",
    response: [],
  };
  constructor(
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public api: ApiService,
    public plt: Platform
  ) {
    console.log("Hello UploadsProvider Provider");
  }
  multipleUpload(file, options?: FileUploadOptions) {
    return new Promise((resolve) => {
      this.api
        .uploadFiles(file, this.multiInfo.url)
        .then((r) => {
          this.multiInfo.response.push(r);
          console.log("multipleUpload => r", r);
          this.multiInfo.sent++;
          if (this.multiInfo.sent < this.multiInfo.toSend) {
            this.multipleUpload(
              this.multiInfo.files[this.multiInfo.sent],
              options
            );
          } else {
            resolve(this.multiInfo.response);
          }
        })
        .catch((e) => {
          console.error("uploadMultiple => catch ", e);
          this.multiInfo.failed.push(this.multiInfo.files[this.multiInfo.sent]);

          this.multiInfo.sent++;
          if (this.multiInfo.sent < this.multiInfo.toSend) {
            this.multipleUpload(
              this.multiInfo.files[this.multiInfo.sent],
              options
            );
          } else {
            resolve(this.multiInfo.response);
          }
        });
    });
  }
  startMultipleUpload(array, url, options?: FileUploadOptions) {
    if (!Array.isArray(array)) {
      array = [array];
    }
    return new Promise((resolve, reject) => {
      this.multiInfo.url = url;
      this.multiInfo.files = array.filter((obj) => obj);
      this.multiInfo.toSend = array.length;
      if (this.multiInfo.files[0]) {
        this.multipleUpload(this.multiInfo.files[0], options).then((r) => {
          resolve(this.multiInfo.response);
          this.onEndMultipleUpload();
        });
      } else {
        reject("Nenhum arquivo foi informado");
      }
    });
  }

  async onEndMultipleUpload() {
    if (this.multiInfo.failed.length) {
      const strFailed = this.multiInfo.failed.map(
        (v) => v.split("/").pop() + "\n"
      );
      const alert = await this.alertCtrl.create({
        header: "Erro ao enviar arquivos",
        message:
          "Não foi possível enviar " +
          this.multiInfo.failed.length +
          " arquivos: \n" +
          strFailed,
      });
      alert.present();
    }
    if (this.multiInfo.failed.length !== this.multiInfo.files.length) {
      const toast = await this.toastCtrl.create({
        message:
          this.multiInfo.files.length -
          this.multiInfo.failed.length +
          " arquivos enviados",
        duration: 3000,
      });
      toast.present();
    }
    this.resetMultiInfo();
  }

  resetMultiInfo() {
    this.multiInfo = {
      files: new Array<string>(),
      toSend: 0,
      sent: 0,
      failed: new Array<string>(),
      url: "",
      response: [],
    };
  }
}
