import { Component, AfterViewInit, ViewChild } from "@angular/core";
import { AlertController, IonRefresher, NavController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AreaService } from "src/app/services/area.service";
import { ConsultasService } from "src/app/services/consultas.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-gestao-listagem",
  templateUrl: "gestao-listagem.component.html",
  styleUrls: ["gestao-listagem.component.scss"],
})
export class GestaoListagemComponent implements AfterViewInit {
  area_id = 0;
  loading = false;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _area: AreaService,
    public api: ApiService,
    public _loading: LoadingService,
    public aplicativos: AplicativosService,
    public _consultas: ConsultasService,
    public helpers: HelpersService,
    public alertCtrl: AlertController
  ) {}

  doSearch(e) {
    this.loading = true;
    this._consultas.getAll(this.area_id).finally(() => (this.loading = false));
  }

  async ngAfterViewInit() {
    this.loading = true;
    try {
      if (!this._area.list.length) await this._area.getAll();
      await this._consultas.getAll();
    } finally {
      this.loading = false;
    }
  }

  categoriasConteudosPage(item) {
    this._area.current = item;
    this.navCtrl.navigateForward("/categorias-conteudos");
  }

  cadastro(item) {
    this.navCtrl.navigateForward("/gestao-tabs", {
      queryParams: { tab: "cadastro" },
    });
    this.pageParams.set("gestao-cadastro", { item: item });
  }

  async excluir(id) {
    (
      await this.alertCtrl.create({
        header: "Você tem certeza?",
        message: "Essa ação não pode ser desfeita",
        buttons: [
          {
            text: "Sim",
            handler: async () => {
              await this._loading.dismiss();
              this._consultas.delete(id).subscribe(() => {
                this._consultas
                  .getAll(this.area_id)
                  .finally(() => this._loading.dismiss());
              });
            },
          },
          {
            text: "Não",
            role: "cancel",
          },
        ],
      })
    ).present();
  }
}
