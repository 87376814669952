import { Component } from "@angular/core";
import { NutriAguaService } from "src/app/services/nutri-agua.service";

@Component({
  selector: "consumo-agua",
  templateUrl: "consumo-agua.html",
  styleUrls: ["consumo-agua.scss"],
})
export class ConsumoAguaComponent {
  text: string;

  constructor(public _agua: NutriAguaService) {
    console.log("Hello ConsumoAguaComponent Component");
    this.text = "Hello World";
  }
}
