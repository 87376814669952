import { AfterViewInit, Component } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Alimento } from "src/app/models/alimento";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { NutriAguaService } from "src/app/services/nutri-agua.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { NutriExerciciosService } from "src/app/services/nutri-exercicios.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-nutri-home",
  templateUrl: "nutri-home.component.html",
  styleUrls: ["nutri-home.component.scss"],
})
export class NutriHomeComponent implements AfterViewInit {
  alimentos = new Array<Alimento>();

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public global: GlobalService,
    public helpers: HelpersService,
    public auth: AuthService,
    public _alimentos: NutriAlimentosService,
    public _agua: NutriAguaService,
    public _exercicios: NutriExerciciosService
  ) {
    this._exercicios.getDay();
  }

  ngAfterViewInit() {
    this._alimentos.getDay(false);
    this._agua.get().subscribe();
  }

  refeicoesPage(tipo) {
    this._alimentos.tipo = tipo;
    this.navCtrl.navigateForward("/refeicoes");
  }
  refeicoesInternaPage(item, tipo) {
    this._alimentos.tipo = tipo;
    this.navCtrl.navigateForward("/refeicoes-interna");
    this.pageParams.set("refeicoes-interna", {
      item: item,
      atualizar: true,
      quantidade: item.pivot.quantidade,
    });
  }
  controleAguaPage() {
    this.navCtrl.navigateForward("/nutri-tabs?tab=controle-agua");
  }

  
}
