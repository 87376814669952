import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import {
  AlertController,
  IonContent,
  NavController,
  ToastController,
} from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { CadastroService } from "src/app/services/cadastro.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "page-cadastro-usuario-second",
  templateUrl: "cadastro-usuario-second.html",
  styleUrls: ["cadastro-usuario-second.scss"],
})
export class CadastroUsuarioSecondPage implements AfterViewInit {
  heightFruits = 0;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("layout") layout: ElementRef;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _cadastro: CadastroService,
    public helpers: HelpersService,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public api: ApiService,
    public auth: AuthService,
    public loading: LoadingService
  ) {}

  async ngAfterViewInit() {
    this.heightFruits =
      (await this.content.getScrollElement()).clientHeight -
      this.layout.nativeElement.clientHeight -
      60;
    if (this.heightFruits < 150) {
      this.heightFruits = 150;
    }
  }

  async cadastroUsuarioThirdPage() {
    /** @todo descomentar validacao */
    if (!this._cadastro.dados.objetivo_descricao) {
      (
        await this.alertCtrl.create({
          header: "Qual o seu objetivo?",
          buttons: [
            {
              text: "Manter peso",
              handler: () => {
                this._cadastro.dados.objetivo_descricao = "Manter Peso";
              },
            },
            {
              text: "Perder peso",
              handler: () => {
                this._cadastro.dados.objetivo_descricao = "Perder Peso";
              },
            },
            {
              text: "Ganhar peso",
              handler: () => {
                this._cadastro.dados.objetivo_descricao = "Ganhar Peso";
              },
            },
          ],
        })
      ).present();
      return;
    }

    if (
      !this._cadastro.dados.objetivo_kg ||
      !(
        this._cadastro.dados.objetivo_kg < 300 &&
        this._cadastro.dados.objetivo_kg > 0
      )
    ) {
      (
        await this.toastCtrl.create({
          message: "Qual o seu objetivo (kg)?",
          duration: 3000,
        })
      ).present();
      return;
    }

    if (
      !this._cadastro.dados.objetivo_kcal ||
      !(
        this._cadastro.dados.objetivo_kcal < 10000 &&
        this._cadastro.dados.objetivo_kcal > 0
      )
    ) {
      (
        await this.toastCtrl.create({
          message: "Qual o seu objetivo (kcal/dia)?",
          duration: 3000,
        })
      ).present();
      return;
    }
    delete this._cadastro.dados.password;
    delete this._cadastro.dados.first_name;
    this.api
      .post("/users/" + this.auth.user.user.id, this._cadastro.dados)
      .subscribe(
        async (r: any) => {
          (
            await this.toastCtrl.create({
              message: "Dados salvos com sucesso!",
              duration: 4000,
            })
          ).present();
          this.loading.dismiss();
          this.auth.setToken(r.token);
          this._cadastro.dados = this.auth.user.user;
          this.navCtrl.navigateForward("/nutri-tabs");
        },
        (e) => this.loading.dismiss()
      );
  }
}
