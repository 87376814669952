import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { NutriAlimentosService } from "./nutri-alimentos.service";
import { HelpersService } from "./helpers.service";

/*
  Generated class for the AguaService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class NutriAguaService {
  item: any = {
    quantidade: "0",
    consumido: 0,
    data: "",
    bebida_200: "0",
    bebida_250: "0",
    bebida_500: "0",
    bebida_700: "0",
    copos: [],
  };

  _save: Subscription;
  _get: Subscription;
  constructor(
    public auth: AuthService,
    public api: ApiService,
    public helpers: HelpersService,
    public _alimentos: NutriAlimentosService
  ) {
    console.log("Hello AguaService Service");
  }

  save() {
    if (this._save && !this._save.closed) {
      this._save.unsubscribe();
    }

    this.item.data = this._alimentos.selectedDate;
    this.updateCoposView();
    return this.api
      .post("/users/" + this.auth.user.user.id + "/agua", this.item)
      .pipe(
        map((r: any) => {
          return this.item;
        })
      );
  }

  get() {
    if (this._get && !this._get.closed) {
      this._get.unsubscribe();
    }
    return this.api
      .get(
        "/users/" +
          this.auth.user.user.id +
          "/agua?data=" +
          this._alimentos.selectedDate
      )
      .pipe(
        map((r: any) => {
          Object.assign(this.item, r);
          this.updateCoposView();
          return this.item;
        })
      );
  }

  updateCoposView() {
    this.item.copos = [];
    let coposNecessarios = Math.round(parseInt(this.item.quantidade) / 250);
    let coposConsumidos = Math.round(
      parseInt(this.item.consumido.toString()) / 250
    );
    this.item.copos = new Array<boolean>(coposNecessarios);
    for (let i = 0; i < coposConsumidos; i++) {
      this.item.copos[i] = true;
    }
  }

  bebeCopo(index) {
    if (this.item.copos[index]) {
      this.item.copos[index] = false;
      this.item.consumido = parseInt(this.item.consumido.toString()) - 250;
    } else {
      this.item.copos[index] = true;
      this.item.consumido = parseInt(this.item.consumido.toString()) + 250;
    }
    this._save = this.save().subscribe();
  }
}
