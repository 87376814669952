import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-star",
  template: `<svg x="0px" y="0px" viewBox="0 0 60.5 57.3">
    <path
      fill="currentColor"
      d="M48.7,57.3c-4.9-3.4-9.9-6.6-14.5-10.4c-3.2-2.7-5.4-2.2-8.5,0.1c-4.4,3.4-8.6,7.2-13.8,9.5
 c0.4-5.7,2.8-10.9,4.7-16.2c1.3-3.7,0.8-5.7-2.6-8.1C9.2,28.8,3.4,26.6,0,21.4c6.3,0,12.6,0.1,19,0.2c1.9,0,3.5-0.1,4.1-2.5
 c1.8-6.2,3.9-12.3,5.8-18.5c1.5-1.3,1.8-0.1,2.2,1.1c1.8,5.3,3.8,10.6,5.3,16c0.9,3.2,2.6,4.1,5.7,4c6.1-0.2,12.3-0.1,18.4-0.1
 C56.1,26.3,50.2,29.2,45,33c-2.4,1.7-2.8,3.1-1.9,5.7C45,44.9,47.7,50.8,48.7,57.3z"
    />
  </svg> `,
})
export class IconStarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
