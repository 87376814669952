import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { IonInfiniteScroll, NavController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/services/api.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-refeicoes-content",
  templateUrl: "./refeicoes-content.component.html",
  styleUrls: ["./refeicoes-content.component.scss"],
})
export class RefeicoesContentComponent implements AfterViewInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  refeicoes: any = new Array(6);


  options = {
    favoritado: 0,
    search: "",
    page: 1,
  };

  loading = false;
  apiSearch: Subscription;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public _alimentos: NutriAlimentosService
  ) {
    
  }
  ngAfterViewInit() {
    this._alimentos.getDestaques().subscribe();
    this.doSearch();
  }

  refeicaoInternaPage(item) {
    this.navCtrl.navigateForward("/refeicoes-interna");
    this.pageParams.set("refeicoes-interna", {
      item: item,
    });
  }

  doSearch() {
    this.loading = true;
    this.options.page = 1;
    this._alimentos._getPaginate = this._alimentos
      .getPaginate(this.options)
      .subscribe(
        (r: any) => {
          this.loading = false;
        },
        (e) => (this.loading = false)
      );
  }

  doInfinite() {
    this.options.page++;
    this._alimentos._getPaginate = this._alimentos
      .getPaginate(this.options)
      .subscribe(
        (r: any) => {
          this.infiniteScroll.complete();
          if (r.data.length === 0) {
            this.options.page--;
          }
        },
        (e) => this.infiniteScroll.complete()
      );
  }

  

  temDestaqueFavoritado() {
    return this._alimentos.destaques.find((obj) => obj.favoritado);
  }
}
