import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class PlanosService {
  planos: any[];

  constructor(public apiService: ApiService) {}

  async boot() {
    const { data } = await this.apiService.get("/planos").toPromise<any>();
    this.planos = data;
  }
  get currentPlano(): any {
    return this.planos?.[0] || {};
  }
}
