import { Component } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { NavController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AreaService } from "src/app/services/area.service";
import { ConsultasService } from "src/app/services/consultas.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-gestao-imagens",
  templateUrl: "gestao-imagens.component.html",
  styleUrls: ["gestao-imagens.component.scss"],
})
export class GestaoImagensComponent {
  area_id = null;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _area: AreaService,
    public api: ApiService,
    public loading: LoadingService,
    public aplicativos: AplicativosService,
    public _consultas: ConsultasService,
    public helpers: HelpersService
  ) {
    this.onRefresher();
  }

  onRefresher() {
    this._consultas.getImagens().then(
      () => {
        this.loading.dismiss();
        console.log(this._consultas.list);
      },
      () => {
        this.loading.dismiss();
      }
    );
  }

  ionViewDidEnter() {
    this._area.getAll().then(
      (r: any) => {
        this.loading.dismiss();
      },
      (e) => {
        this.loading.dismiss();
      }
    );
  }

  doSearch(e) {
    this._consultas.getImagens(this.area_id).then(
      () => {
        this.loading.dismiss();
      },
      () => {
        this.loading.dismiss();
      }
    );
  }

  shareImg(item) {
    Plugins.Share.share({
      url: item.thumbnail_principal,
    });
  }
}
