import { Component, HostBinding, Input, OnInit } from "@angular/core";

export interface CreditCardInterface {
  name?: string;
  number?: string;
  month?: string;
  year?: string;
  cvv?: string;
}

@Component({
  selector: "app-credit-card",
  templateUrl: "./credit-card.component.html",
  styleUrls: ["./credit-card.component.scss"],
})
export class CreditCardComponent implements OnInit {
  @Input() card: CreditCardInterface = {};
  constructor() {}

  ngOnInit() {}

  @HostBinding("class.card") cardCss = true;
}
