import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
type CentralTab = "central-noticias" | "central-home" | "central-notificacoes";
@Component({
  templateUrl: "central-tabs.html",
})
export class CentralTabsPage implements AfterViewInit, OnDestroy {
  tab: CentralTab = "central-home";
  queryParamsSub: Subscription;
  constructor(public route: ActivatedRoute) {}
  ngAfterViewInit() {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.setTab(params.tab);
      } else {
        this.setTab("central-home");
      }
    });
  }
  ngOnDestroy() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }
  setTab(tab: CentralTab) {
    this.tab = tab;
  }
}
