import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import ptBr from "@angular/common/locales/pt";
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { FileTransfer } from "@ionic-native/file-transfer/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { iosTransitionAnimation } from "@ionic/core/dist/collection/utils/transition/ios.transition.js";
import { BrMaskerModule } from "br-mask";
import { NgxMaskModule } from "ngx-mask";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AudiosComponent } from "./components/audios/audios.component";
import { CategoriasConteudosContentComponent } from "./components/categorias-conteudos-content/categorias-conteudos-content.component";
import { CategoriasListaComponent } from "./components/categorias-lista/categorias-lista.component";
import { CategoriasMindComponent } from "./components/categorias-mind/categorias-mind.component";
import { CategoriasComponent } from "./components/categorias/categorias.component";
import { ConsumoAguaComponent } from "./components/consumo-agua/consumo-agua";
import { ContatoFormComponent } from "./components/contato-form/contato-form.component";
import { ControleAguaContentComponent } from "./components/controle-agua-content/controle-agua-content.component";
import { FavoritosContentComponent } from "./components/favoritos-content/favoritos-content.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FraseDoDiaComponent } from "./components/frase-do-dia/frase-do-dia.component";
import { HeaderComponent } from "./components/header/header";
import { HomeTabbarComponent } from "./components/home-tabbar/home-tabbar";
import { IconBookComponent } from "./components/icons/icon-book/icon-book.component";
import { IconBooksComponent } from "./components/icons/icon-books/icon-books.component";
import { IconExerciseComponent } from "./components/icons/icon-exercise.component";
import { IconHandComponent } from "./components/icons/icon-hand/icon-hand.component";
import { IconKcalComponent } from "./components/icons/icon-kcal.component";
import { IconQuotesComponent } from "./components/icons/icon-quotes.component";
import { IconRestaurantComponent } from "./components/icons/icon-restaurant.component";
import { IconStarComponent } from "./components/icons/icon-star/icon-star.component";
import { IconUtensilsComponent } from "./components/icons/icon-utensils/icon-utensils.component";
import { IconWaterComponent } from "./components/icons/icon-water.component";
import { NutriCalculoCaloricoContentComponent } from "./components/nutri-calculo-calorico-content/nutri-calculo-calorico-content.component";
import { NutriExerciciosContentComponent } from "./components/nutri-exercicios-content/nutri-exercicios-content.component";
import { PdfsEDicasContentComponent } from "./components/pdfs-e-dicas-content/pdfs-e-dicas-content.component";
import { PerfilContentComponent } from "./components/perfil-content/perfil-content.component";
import { RadioWeekComponent } from "./components/radio-week/radio-week";
import { RefeicoesContentComponent } from "./components/refeicoes-content/refeicoes-content.component";
import { SublistItemDesktopComponent } from "./components/sublist-item-desktop/sublist-item-desktop";
import { SublistItemComponent } from "./components/sublist-item/sublist-item";
import { ImagePreloadDirective } from "./directives/image-preload/image-preload";
import { NavPopDirective } from "./directives/nav-pop.directive";
import { AplicativosPage } from "./pages/aplicativos/aplicativos";
import { CadastroUsuarioSecondPage } from "./pages/cadastro-usuario-second/cadastro-usuario-second";
import { CadastroUsuarioPage } from "./pages/cadastro-usuario/cadastro-usuario";
import { CategoriasConteudosPage } from "./pages/categorias-conteudos/categorias-conteudos";
import { CentralHomePage } from "./pages/central-home/central-home";
import { CentralNoticiasInternaPage } from "./pages/central-noticias-interna/central-noticias-interna";
import { CentralNoticiasPage } from "./pages/central-noticias/central-noticias";
import { CentralNotificacoesPage } from "./pages/central-notificacoes/central-notificacoes";
import { CentralTabsPage } from "./pages/central-tabs/central-tabs";
import { CertificadosPagarPage } from "./pages/certificados-pagar/certificados-pagar";
import { CodigoPage } from "./pages/codigo/codigo";
import { ConhecaPage } from "./pages/conheca/conheca";
import { ContatoPage } from "./pages/contato/contato";
import { NutriCalculoCaloricoPage } from "./pages/nutri-calculo-calorico/nutri-calculo-calorico";
import { NutriExercicioInternaPage } from "./pages/nutri-exercicio-interna/nutri-exercicio-interna";
import { NutriExerciciosPage } from "./pages/nutri-exercicios/nutri-exercicios";
import { NutriTabsPage } from "./pages/nutri-tabs/nutri-tabs";
import { DefaultTabsPage } from "./pages/default-tabs/default-tabs";
import { EasymindTabsPage } from "./pages/easymind-tabs/easymind-tabs";
import { EsqueciSenhaPage } from "./pages/esqueci-senha/esqueci-senha";
import { FacaPartePage } from "./pages/faca-parte/faca-parte";
import { FavoritosPage } from "./pages/favoritos/favoritos";
import { GestaoCadastroComponent } from "./components/gestao-cadastro/gestao-cadastro.component";
import { GestaoHomeComponent } from "./components/gestao-home/gestao-home.component";
import { GestaoImagensComponent } from "./components/gestao-imagens/gestao-imagens.component";
import { GestaoListagemComponent } from "./components/gestao-listagem/gestao-listagem.component";
import { GestaoTabsPage } from "./pages/gestao-tabs/gestao-tabs";
import { LoginCodigoPage } from "./pages/login-codigo/login-codigo";
import { LoginPage } from "./pages/login/login";
import { PdfsEDicasPage } from "./pages/pdfs-e-dicas/pdfs-e-dicas";
import { PerfilPage } from "./pages/perfil/perfil";
import { PlayerPage } from "./pages/player/player";
import { NutriRefeicoesInternaPage } from "./pages/refeicoes-interna/refeicoes-interna";
import { RefeicoesPage } from "./pages/refeicoes/refeicoes";
import { SearchPage } from "./pages/search/search";
import { FilterPipe } from "./pipes/filter.pipe";
import { LinkifyPipe } from "./pipes/linkify.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { ToFixedPipe } from "./pipes/to-fixed.pipe";
import { NutriHomeComponent } from "./components/nutri-home/nutri-home.component";
import { CreditCardComponent } from "./components/credit-card/credit-card.component";
import { Camera } from "@ionic-native/camera/ngx";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { ContratarPagar } from "./pages/contratar-pagar/contratar-pagar";
import { ContratarPage } from "./pages/contratar/contratar";
import { CreditCardFormComponent } from "./components/credit-card-form/credit-card-form.component";
import { AuthPagesDirective } from './directives/auth-pages.directive';
import { InterceptorModule } from './interceptors/interceptor.module';

registerLocaleData(ptBr);
@NgModule({
  declarations: [
    AppComponent,
    AplicativosPage,
    AudiosComponent,
    CadastroUsuarioSecondPage,
    CadastroUsuarioPage,
    CategoriasConteudosPage,
    CentralHomePage,
    CentralNoticiasInternaPage,
    CentralNoticiasPage,
    CentralNotificacoesPage,
    CentralTabsPage,
    CertificadosPagarPage,
    CodigoPage,
    ConhecaPage,
    ContatoPage,
    NutriCalculoCaloricoPage,
    NutriExercicioInternaPage,
    NutriExerciciosPage,
    NutriHomeComponent,
    NutriRefeicoesInternaPage,
    RefeicoesPage,
    NutriTabsPage,
    DefaultTabsPage,
    EasymindTabsPage,
    EsqueciSenhaPage,
    FacaPartePage,
    FavoritosPage,
    GestaoCadastroComponent,
    GestaoHomeComponent,
    GestaoImagensComponent,
    GestaoListagemComponent,
    GestaoTabsPage,
    LoginCodigoPage,
    LoginPage,
    PdfsEDicasPage,
    PerfilPage,
    PlayerPage,
    SearchPage,
    ConsumoAguaComponent,
    HeaderComponent,
    HomeTabbarComponent,
    RadioWeekComponent,
    SublistItemComponent,
    SublistItemDesktopComponent,
    FilterPipe,
    LinkifyPipe,
    SafePipe,
    ToFixedPipe,
    ImagePreloadDirective,
    NavPopDirective,
    FooterComponent,
    IconBooksComponent,
    IconUtensilsComponent,
    IconHandComponent,
    IconStarComponent,
    IconBookComponent,
    IconRestaurantComponent,
    IconWaterComponent,
    IconExerciseComponent,
    IconKcalComponent,
    IconQuotesComponent,
    ContatoFormComponent,
    CategoriasConteudosContentComponent,
    FavoritosContentComponent,
    PdfsEDicasContentComponent,
    CategoriasComponent,
    PerfilContentComponent,
    RefeicoesContentComponent,
    ControleAguaContentComponent,
    NutriExerciciosContentComponent,
    NutriCalculoCaloricoContentComponent,
    CategoriasListaComponent,
    CategoriasMindComponent,
    FraseDoDiaComponent,
    CreditCardComponent,
    ContratarPage,
    ContratarPagar,
    CreditCardFormComponent,
    AuthPagesDirective,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      backButtonText: "",
      backButtonIcon: "chevron-back",
      mode: "md",
      navAnimation: iosTransitionAnimation,
      swipeBackEnabled: true,
    }),
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    BrMaskerModule,
    InterceptorModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    OneSignal,
    FileTransfer,
    { provide: LOCALE_ID, useValue: "pt" },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: "BRL",
    },
    Camera,
    ImagePicker,
    InAppPurchase2,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
