import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AplicativosService } from "./aplicativos.service";

/*
  Generated class for the CategoriasService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class CategoriasService {
  list: any = [];
  watchOpen = new Subject<number>();
  private _current: { [x: string]: any } = {};
  set current(current: { [x: string]: any }) {
    window.localStorage.setItem("categorias_current", JSON.stringify(current));
    this._current = this.parseCategoria(current);
  }
  get current() {
    return this._current;
  }
  constructor(
    public api: ApiService,
    public toastCtrl: ToastController,
    public aplicativos: AplicativosService
  ) {
    try {
      this._current = JSON.parse(
        window.localStorage.getItem("categorias_current")
      );
    } catch {
      this._current = {};
    }
  }

  get(url?) {
    if (!url) {
      url = "/categorias?aplicativo_id=" + this.aplicativos.item.id;
    }
    return this.api.get(url);
  }

  getAll(options: any = {}) {
    options.completo = options.completo !== undefined ? options.completo : true;
    if (options.completo) {
      options.completo = "&completo=true";
    } else {
      options.completo = "";
    }
    const opened = this.list.find((categoria) => categoria.open);
    return this.get(
      "/categorias?aplicativo_id=" + this.aplicativos.item.id + options.completo
    )
      .pipe(
        map((r: any) => {
          this.list = r;
          this.list = this.list.map((categoria) =>
            this.parseCategoria(categoria, opened)
          );

          return this.list;
        })
      )
      .toPromise();
  }

  getTopicos(categoria_id) {
    return this.get("/categorias/" + categoria_id + "/topicos").toPromise();
  }

  private parseCategoria(categoria, opened?) {
    categoria.open = opened && opened.id == categoria.id ? true : false;

    categoria.topicos = categoria.topicos?.map((topico) => {
      topico.topicos =
        topico.topicos?.map((topico2) => {
          topico2.temAudio = topico2.conteudos.find(
            (conteudo) => conteudo.tipo == "audio"
          );
          topico2.temVideo = topico2.conteudos.find(
            (conteudo) => conteudo.tipo == "video"
          );

          return topico2;
        }) || [];

      topico.temAudio =
        topico.conteudos?.find((conteudo) => conteudo.tipo == "audio") ||
        topico.topicos?.find((topico2) => topico2.temAudio);
      topico.temVideo =
        topico.conteudos?.find((conteudo) => conteudo.tipo == "video") ||
        topico.topicos?.find((topico2) => topico2.temVideo);

      return topico;
    });

    categoria.temAudio = categoria.topicos?.find((topico) => topico.temAudio);
    categoria.temVideo = categoria.topicos?.find((topico) => topico.temVideo);
    return categoria;
  }

  visualizar(conteudo_id) {
    this.list = this.list.map((categoria) => {
      if (Array.isArray(categoria.topicos)) {
        categoria.topicos = categoria.topicos.map((topico) => {
          if (Array.isArray(topico.conteudos)) {
            topico.conteudos = topico.conteudos.map((conteudo) => {
              if (conteudo.id == conteudo_id) {
                if (!conteudo.assistido) {
                  // assistiu conteudo adiciona um count nos assistidos
                  categoria.conteudos_assistido_count++;
                }
                conteudo.assistido = 1;
              }
              return conteudo;
            });
          }

          if (Array.isArray(topico.topicos)) {
            topico.topicos = topico.topicos.map((topico2) => {
              if (Array.isArray(topico2.conteudos)) {
                topico2.conteudos = topico2.conteudos.map((conteudo2) => {
                  if (conteudo2.id == conteudo_id) {
                    if (!conteudo2.assistido) {
                      // assistiu conteudo adiciona um count nos assistidos, somente se o conteudo ainda nao foi assistido ele aumenta o count
                      categoria.conteudos_assistido_count++;
                    }
                    conteudo2.assistido = 1;
                  }
                  return conteudo2;
                });
              }
              return topico2;
            });
          }

          return topico;
        });
      }
      return categoria;
    });
    return this.api.patch("/conteudos/" + conteudo_id + "/visualizar");
  }

  async favoritar(conteudo) {
    console.log(`favoritar`, conteudo);
    return new Promise((resolve) => {
      if (conteudo.favoritado != 1) {
        this.patchFavoritar(conteudo.id).subscribe(async () => {
          (
            await this.toastCtrl.create({
              message: conteudo.titulo + " adicionado aos seus favoritos ",
              duration: 3000,
            })
          ).present();
          resolve(true);
        });
      } else {
        this.deleteFavoritar(conteudo.id).subscribe(async () => {
          (
            await this.toastCtrl.create({
              message: conteudo.titulo + " removido de seus favoritos ",
              duration: 3000,
            })
          ).present();
          resolve(true);
        });
      }
    });
  }
  patchFavoritar(conteudo_id) {
    this.list = this.list.map((categoria) => {
      if (Array.isArray(categoria.topicos)) {
        categoria.topicos = categoria.topicos.map((topico) => {
          if (Array.isArray(topico.conteudos)) {
            topico.conteudos = topico.conteudos.map((conteudo) => {
              if (conteudo.id == conteudo_id) {
                conteudo.favoritado = 1;
              }
              return conteudo;
            });
          }

          if (Array.isArray(topico.topicos)) {
            topico.topicos = topico.topicos.map((topico2) => {
              if (Array.isArray(topico2.conteudos)) {
                topico2.conteudos = topico2.conteudos.map((conteudo2) => {
                  if (conteudo2.id == conteudo_id) {
                    conteudo2.favoritado = 1;
                  }
                  return conteudo2;
                });
              }
              return topico2;
            });
          }

          return topico;
        });
      }
      return categoria;
    });
    return this.api.patch("/conteudos/" + conteudo_id + "/favoritar");
  }

  deleteFavoritar(conteudo_id) {
    this.list = this.list.map((categoria) => {
      if (Array.isArray(categoria.topicos)) {
        categoria.topicos = categoria.topicos.map((topico) => {
          if (Array.isArray(topico.conteudos)) {
            topico.conteudos = topico.conteudos.map((conteudo) => {
              if (conteudo.id == conteudo_id) {
                conteudo.favoritado = 0;
              }
              return conteudo;
            });
          }

          if (Array.isArray(topico.topicos)) {
            topico.topicos = topico.topicos.map((topico2) => {
              if (Array.isArray(topico2.conteudos)) {
                topico2.conteudos = topico2.conteudos.map((conteudo2) => {
                  if (conteudo2.id == conteudo_id) {
                    conteudo2.favoritado = 0;
                  }
                  return conteudo2;
                });
              }
              return topico2;
            });
          }

          return topico;
        });
      }
      return categoria;
    });
    return this.api.delete("/conteudos/" + conteudo_id + "/favoritar");
  }

  openItem(i) {
    this.list = this.list.map((obj, index) => {
      //abre aquele especifico
      if (index == i) {
        obj.open = true;
      } else {
        obj.open = false;
      }
      return obj;
    });
  }
  closeItem(i) {
    this.list = this.list.map((obj, index) => {
      obj.open = false;
      return obj;
    });
  }

  closeAll() {
    this.list = this.list.map((obj) => {
      obj.open = false;
      return obj;
    });
  }

  find(categoria_id) {
    return this.list.find((categoria) => categoria.id == categoria_id);
  }
}
