import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { IonContent, IonRefresher, NavController } from "@ionic/angular";
import { CategoriasConteudosContentComponent } from "src/app/components/categorias-conteudos-content/categorias-conteudos-content.component";
import { CategoriasService } from "src/app/services/categorias.service";
import { GlobalService } from "src/app/services/global.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

import { CertificadosPagarPage } from "../certificados-pagar/certificados-pagar";

/**
 * Generated class for the ModuloPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: "page-categorias-conteudos",
  templateUrl: "categorias-conteudos.html",
})
export class CategoriasConteudosPage {
  @ViewChild(IonRefresher) refresher: IonRefresher;
  @ViewChild(CategoriasConteudosContentComponent)
  categoriasConteudosContent: CategoriasConteudosContentComponent;

  constructor(public _categorias: CategoriasService) {}

  ionViewWillEnter() {}

  onRefresher() {
    this.categoriasConteudosContent
      .getConteudos()
      .finally(() => this.refresher.complete());
  }
}
