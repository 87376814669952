import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

/*
  Generated class for the LoadingService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class LoadingService {
  loading?: HTMLIonLoadingElement;
  constructor(public loadingCtrl: LoadingController) {
    console.log("Hello LoadingService Service");
  }

  async present() {
    if(this.loading) {
      return
    }
    // this.loading = this.loadingCtrl.create({
    //   spinner: 'hide',
    //   content: `
    //   <div class="custom-spinner-container">
    //     <img class="loading" width="80" height="80" src="assets/loading.gif" />
    //   </div>`
    // });

    this.loading = await this.loadingCtrl.create({
      cssClass: 'custom-spinner'
    });
    return this.loading.present();
  }

  dismiss() {
    return new Promise((resolve) => {
      if (this.loading) {
        return this.loading.dismiss(resolve(true)).catch((error) => {
          console.log("loading error: ", error);
        }).finally(() => {
          this.loading = undefined
        });
      } else {
        resolve(true);
      }
    });
  }
}
