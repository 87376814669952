import { Component, ViewChild } from "@angular/core";
import { IonRefresher, NavController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AreaService } from "src/app/services/area.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "page-central-home",
  templateUrl: "central-home.html",
})
export class CentralHomePage {
  constructor() {}
}
