import { Component, ViewChild, ElementRef } from "@angular/core";
import { IonContent, NavController, ToastController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";

/**
 * Generated class for the RefeicaoInternaPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
import { PageParamsService } from "src/app/services/page-params.service";
@Component({
  selector: "page-refeicoes-interna",
  templateUrl: "refeicoes-interna.html",
  styleUrls: ["refeicoes-interna.scss"],
})
export class NutriRefeicoesInternaPage {
  item: any = {};

  gordura;
  energia;
  carboidratos;
  proteina;

  quantidade;

  atualizar;

  heightFruits = 0;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("layout") layout: ElementRef;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public auth: AuthService,
    public helpers: HelpersService,
    public _alimentos: NutriAlimentosService,
    public toastCtrl: ToastController,
    public loading: LoadingService
  ) {}
  ionViewWillEnter() {
    this.item = this.pageParams.get("refeicoes-interna", "item");

    this.atualizar = this.pageParams.get("refeicoes-interna", "atualizar");

    this.quantidade = this.pageParams.get("refeicoes-interna", "quantidade");

    this.gordura = this.item.gordura;
    this.energia = this.item.energia;
    this.carboidratos = this.item.carboidratos;
    this.proteina = this.item.proteina;

    if (this.atualizar) {
      this.calcular();
    }
  }

  async ngAfterViewInit() {
    console.log("ngAfterViewInit RefeicaoInternaPage");
    this.heightFruits =
      (await this.content.getScrollElement()).clientHeight -
      this.layout.nativeElement.clientHeight -
      60;
    if (this.heightFruits < 150) {
      this.heightFruits = 150;
    }
  }

  async salvar() {
    if (!this.quantidade || this.quantidade < 0) {
      (
        await this.toastCtrl.create({
          message: "Por favor, informe a quantidade desejada",
          duration: 4000,
        })
      ).present();
      return;
    }
    await this.loading.present();
    this.request().subscribe(async (r: any) => {
      (
        await this.toastCtrl.create({
          message:
            "Alimento salvo para o dia " +
            this.helpers
              .moment(this._alimentos.selectedDate, "YYYY-MM-DD")
              .format("LL") +
            " para " +
            this._alimentos.tipos[this._alimentos.tipo],
          duration: 4000
        })
      ).present();
      this.navCtrl.pop();
      this._alimentos.getDay().then(() => {
        this.loading.dismiss();
      });
    });
  }

  favoritar() {
    this._alimentos
      .favoritar(this.item)
      .then((alimento) => (this.item = alimento));
  }

  calcular() {
    this.energia = Math.ceil((this.item.energia * this.quantidade) / 100);
    this.gordura = ((this.item.gordura * this.quantidade) / 100).toFixed(2);
    this.carboidratos = (
      (this.item.carboidratos * this.quantidade) /
      100
    ).toFixed(2);
    this.proteina = ((this.item.proteina * this.quantidade) / 100).toFixed(2);
  }

  request() {
    if (this.atualizar) {
      return this.api.patch("/users/" + this.auth.user.user.id + "/alimentos", {
        alimento_id: this.item.id,
        data: this._alimentos.selectedDate,
        quantidade: this.quantidade,
        tipo: this._alimentos.tipo,
      });
    } else {
      return this.api.post("/users/" + this.auth.user.user.id + "/alimentos", {
        alimento_id: this.item.id,
        data: this._alimentos.selectedDate,
        quantidade: this.quantidade,
        tipo: this._alimentos.tipo,
      });
    }
  }
}
