import { Component, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { IonContent, NavController, ToastController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { NutriExerciciosService } from "src/app/services/nutri-exercicios.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";

/**
 * Generated class for the ExercicioInternaPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
import { PageParamsService } from "src/app/services/page-params.service";
@Component({
  selector: "page-nutri-exercicio-interna",
  templateUrl: "nutri-exercicio-interna.html",
  styleUrls: ["nutri-exercicio-interna.scss"],
})
export class NutriExercicioInternaPage implements AfterViewInit {

  item: any = {};
  energia;

  quantidade;

  atualizar;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public api: ApiService,
    public auth: AuthService,
    public helpers: HelpersService,
    public _exercicios: NutriExerciciosService,
    public _alimentos: NutriAlimentosService,
    public toastCtrl: ToastController,
    public loading: LoadingService,
    public global: GlobalService
  ) {
    this.item = this.pageParams.get("nutri-exercicio-interna", "item");

    this.atualizar = this.pageParams.get(
      "nutri-exercicio-interna",
      "atualizar"
    );
    this.quantidade = this.pageParams.get(
      "nutri-exercicio-interna",
      "quantidade"
    );

    this.energia = this.item.energia;
  }

  async ngAfterViewInit() {
   
  }

  async salvar() {
    if (!this.quantidade || this.quantidade < 0) {
      (
        await this.toastCtrl.create({
          message: "Por favor, informe a quantidade desejada",
          duration: 4000,
        })
      ).present();
      return;
    }
    await this.loading.present();
    this.request().subscribe(async (r: any) => {
      (
        await this.toastCtrl.create({
          message:
            "Atividade salva para o dia " +
            this.helpers
              .moment(this._alimentos.selectedDate, "YYYY-MM-DD")
              .format("LL"),
          duration: 4000,
        })
      ).present();
      this._exercicios.getDay().then(() => {
        this.navCtrl.pop();
        this.loading.dismiss();
      });
    });
  }

  calcular() {
    this.energia = Math.ceil((this.item.energia * this.quantidade) / 60);
  }

  request() {
    if (this.atualizar) {
      return this.api.patch(
        "/users/" + this.auth.user.user.id + "/exercicios",
        {
          exercicio_id: this.item.id,
          data: this._alimentos.selectedDate,
          quantidade: this.quantidade,
        }
      );
    } else {
      return this.api.post("/users/" + this.auth.user.user.id + "/exercicios", {
        exercicio_id: this.item.id,
        data: this._alimentos.selectedDate,
        quantidade: this.quantidade,
      });
    }
  }
}
