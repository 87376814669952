/**
 * Generated class for the CertificadosPagarPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */
import { Component, AfterViewInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NavController, AlertController, Platform, ToastController } from "@ionic/angular";
import { CreditCardInterface } from "src/app/components/credit-card/credit-card.component";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { CategoriasService } from "src/app/services/categorias.service";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
@Component({
  selector: "page-certificados-pagar",
  templateUrl: "certificados-pagar.html",
  styleUrls: ["certificados-pagar.scss"],
})
export class CertificadosPagarPage implements AfterViewInit {
  cc: CreditCardInterface = {
    name: "",
    number: "",
    month: "",
    year: "",
    cvv: "",
  };
  emailsArray = [this.auth.user.user.email];
  nome = "";
  categoria;
  certificado: any = {};
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public helpers: HelpersService,
    public auth: AuthService,
    public api: ApiService,
    public loading: LoadingService,
    public alertCtrl: AlertController,
    public categorias: CategoriasService,
    public global: GlobalService,
    public plt: Platform,
    public toastCtrl: ToastController
  ) {
    this.init();
  }

  init() {
    this.getCertificados();
    this.categoria = this.pageParams.get("certificados-pagar")?.categoria;
    if (!this.categoria) {
      this.navCtrl.pop();
      return;
    }
    this.categorias.get("/categorias/" + this.categoria.id).subscribe(
      (r: any) => {
        if (r.conteudos_assistido_count != r.conteudos_assistido_count) {
          this.navCtrl.pop();
          this.helpers.toast(
            "Você precisa ver todos os conteúdos antes de poder fazer o download do certificado"
          );
        }
      },
      (e) => {
        this.navCtrl.pop();
      }
    );
  }

  async getCertificados() {
    await this.loading.present();
    this.api.get("/certificados").subscribe(
      (r: any) => {
        console.log(r);
        this.certificado = r[0];
        this.loading.dismiss();
      },
      async (e) => {
        this.loading.dismiss();
        (
          await this.alertCtrl.create({
            header: "Ops...",
            message:
              "Não foi possível conectar-se aos servidor da EasyLife, deseja tentar novamente?",
            buttons: [
              {
                role: "cancel",
                text: "Não",
                handler: () => {
                  this.navCtrl.pop();
                },
              },
              {
                text: "Sim",
                handler: () => {
                  this.getCertificados();
                },
              },
            ],
          })
        ).present();
      }
    );
  }
  ngAfterViewInit() {
    console.log("ngAfterViewInit CertificadosPagarPage");
  }

  async onSubmit(form: NgForm) {
    this.helpers.formMarkAllTouched(form);
    if (!this.nome.trim().length) {
      return this.helpers.toast(
        "Preencha o nome que será colocado no certificado"
      );
    }
    if (!this.emailsArray[0].length) {
      return this.helpers.toast(
        "Coloque pelo menos 1 e-mail que será enviado o certificado"
      );
    }
    if (this.cc.name.split(" ").length < 2) {
      return this.helpers.toast(
        "Preencha o nome completo que está no cartão de crédito"
      );
    }
    if (!this.cc.month.trim().length) {
      return this.helpers.toast(
        "Preencha o mês de validade do cartão de crédito"
      );
    }
    if (this.cc.year.length != 2) {
      return this.helpers.toast(
        "Preencha o ano de validade do cartão de crédito"
      );
    }
    if (!this.cc.cvv.length) {
      return this.helpers.toast(
        "Preencha o código de segurança do cartão de crédito"
      );
    }

    if (!form.valid) {
      return this.helpers.toast("Verifique os campos em vermelho");
    }
    await this.loading.present();
    this.api
      .post("/certificados/pagar", {
        nome: this.nome,
        cc: this.cc,
        emails: this.emailsArray.join(","),
        categoria_id: this.categoria.id,
        certificado_id: this.certificado.id,
      })
      .subscribe(
        async (r: any) => {
          this.loading.dismiss();
          (
            await this.alertCtrl.create({
              message: r.message,
              buttons: [
                {
                  text: "Ver certificado",
                  handler: () => {
                    if (this.plt.is("ios")) {
                      this.helpers.openLink(r.link, "_system");
                    } else {
                      this.helpers.openLink(r.link, "_blank");
                    }
                  },
                },
              ],
            })
          ).present();
          this.navCtrl.pop();
        },
        async (e) => {
          (await this.toastCtrl.create({
            message: 'Ops... parece que há alguma informação incorreta. Por favor, verifique e tente novamente',
            duration: 4000
          })).present()
          this.loading.dismiss();
        }
      );
  }
}
