import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-icon-books",
  templateUrl: "icon-books.component.svg",
})
export class IconBooksComponent implements OnInit {
  @Input() active = false;
  constructor() {}

  ngOnInit() {}
}
