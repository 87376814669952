import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
import {
  IonRefresher,
  NavController,
  ToastController,
  AlertController,
} from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { PdfsEDicasContentComponent } from "src/app/components/pdfs-e-dicas-content/pdfs-e-dicas-content.component";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { PdfsService } from "src/app/services/pdfs.service";

/**
 * Generated class for the PdfsEDicasPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: "page-pdfs-e-dicas",
  templateUrl: "pdfs-e-dicas.html",
})
export class PdfsEDicasPage implements AfterViewInit, OnDestroy {
  @ViewChild(IonRefresher) refresher: IonRefresher;
  @ViewChild(PdfsEDicasContentComponent)
  pdfsEDicasContent: PdfsEDicasContentComponent;
  subscription: Subscription;

  constructor(public _pdfs: PdfsService) {
    
  }
  ngAfterViewInit() {
    this.subscription = this.pdfsEDicasContent.onRequestEnd.subscribe(() => {
      this.refresher.complete();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRefresher() {
    this.pdfsEDicasContent.getPdfsEDicas();
  }
}
