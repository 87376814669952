/**
 * Generated class for the CadastroUsuarioPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { IonContent, NavController, ToastController } from "@ionic/angular";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { CadastroService } from "src/app/services/cadastro.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
@Component({
  selector: "page-cadastro-usuario",
  templateUrl: "cadastro-usuario.html",
  styleUrls: ["cadastro-usuario.scss"],
})
export class CadastroUsuarioPage implements AfterViewInit {
  heightFruits = 0;
  maxDate = this.helpers.moment().subtract(16, "years").format("YYYY-MM-DD");
  @ViewChild("dataNascimento") dataNascimento: ElementRef;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("layout") layout: ElementRef;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _cadastro: CadastroService,
    public helpers: HelpersService,
    public toastCtrl: ToastController,
    public aplicativos: AplicativosService,
    public loading: LoadingService
  ) {}

  async ngAfterViewInit() {
    setTimeout(async () => {
      this.heightFruits =
        (await this.content.getScrollElement()).clientHeight -
        this.layout.nativeElement.clientHeight -
        60;
      if (this.heightFruits < 150) {
        this.heightFruits = 150;
      }
    }, 50);
  }

  dataNascimentoPicker() {
    this.dataNascimento.nativeElement.click();
  }

  async cadastroUsuarioSecondPage() {
    if (!this._cadastro.dados.genero) {
      (
        await this.toastCtrl.create({
          message: "Qual o seu gênero?",
          duration: 3000,
        })
      ).present();
      return;
    }
    if (!this._cadastro.dados.data_nascimento) {
      (
        await this.toastCtrl.create({
          message: "Qual a sua data de nascimento?",
          duration: 3000,
        })
      ).present();
      return;
    }
    if (
      !this._cadastro.dados.altura ||
      !(this._cadastro.dados.altura < 300 && this._cadastro.dados.altura > 0)
    ) {
      (
        await this.toastCtrl.create({
          message: "Qual a sua altura?",
          duration: 3000,
        })
      ).present();
      return;
    }
    if (
      !this._cadastro.dados.inicio_kg ||
      !(
        this._cadastro.dados.inicio_kg < 300 &&
        this._cadastro.dados.inicio_kg > 0
      )
    ) {
      (
        await this.toastCtrl.create({
          message: "Qual o seu peso?",
          duration: 3000,
        })
      ).present();
      return;
    }
    this._cadastro.dados.atual_kg = this._cadastro.dados.inicio_kg;
    this.navCtrl.navigateForward("/cadastro-usuario-second", {});
  }

  async easylifePage() {
    await this.loading.present();
    this.navCtrl.navigateRoot("/default-tabs");

    setTimeout(() => {
      this.aplicativos.setItem(this.aplicativos.getEasyfitLife());
      this.loading.dismiss();
    }, 50);
  }
}
