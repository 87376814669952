import { AfterViewInit, Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AuthService } from "src/app/services/auth.service";
import { NutriAguaService } from "src/app/services/nutri-agua.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { HelpersService } from "src/app/services/helpers.service";
import { PageParamsService } from "src/app/services/page-params.service";

@Component({
  selector: "app-controle-agua-content",
  templateUrl: "./controle-agua-content.component.html",
  styleUrls: ["./controle-agua-content.component.scss"],
})
export class ControleAguaContentComponent implements AfterViewInit {
  qtdAgua: number = 0;

  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public auth: AuthService,
    public api: ApiService,
    public _alimentos: NutriAlimentosService,
    public _agua: NutriAguaService,
    public helpers: HelpersService
  ) {}

  ngAfterViewInit() {
    console.log("ngAfterViewInit ControleAguaPage");
  }
  ionViewWillEnter() {
    this._agua._get = this._agua.get().subscribe();
  }
  save() {
    if (parseInt(this._agua.item.quantidade) < this._agua.item.consumido) {
      this._agua.item.consumido = this._agua.item.quantidade;
      console.log("blalblblba");
    }
    this._agua._save = this._agua
      .save()
      .subscribe((r: any) => this._agua.updateCoposView());
  }
}
