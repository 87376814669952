/**
 * Generated class for the EsqueciSenhaPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, AfterViewInit } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { NavController, ToastController } from "@ionic/angular";
import { GlobalService } from "src/app/services/global.service";
import { HelpersService } from "src/app/services/helpers.service";
import { LoadingService } from "src/app/services/loading.service";
import { PageParamsService } from "src/app/services/page-params.service";
import { FacaPartePage } from "../faca-parte/faca-parte";

@Component({
  selector: "page-esqueci-senha",
  templateUrl: "esqueci-senha.html",
})
export class EsqueciSenhaPage implements AfterViewInit {
  // @ViewChild('video') video: ElementRef;
  email = "";
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public toastCtrl: ToastController,
    public helpers: HelpersService,
    public _loading: LoadingService,
    public global: GlobalService,
    public http: HttpClient
  ) {}

  ngAfterViewInit() {
    // this.video.nativeElement.muted = true;
    console.log("ngAfterViewInit EsqueciSenhaPage");
  }



  async onSubmit() {
    if (!this.helpers.isEmail(this.email)) {
      (
        await this.toastCtrl.create({
          message: "Informe um e-mail válido",
          duration: 4000,
        })
      ).present();
      return;
    }
    this._loading.present();
    this.http
      .post(this.global.apiUrl + "/forgot-password", { email: this.email })
      .subscribe(
        async (r: any) => {
          this._loading.dismiss();
          (
            await this.toastCtrl.create({
              message: "Um e-mail foi enviado para você!",
              duration: 4000,
            })
          ).present();
          this.navCtrl.pop();
        },
        async (e: HttpErrorResponse) => {
          this._loading.dismiss();
          if (e.status == 404) {
            (
              await this.toastCtrl.create({
                message: "Verifique se seu e-mail foi digitado corretamente",
                duration: 4000,
              })
            ).present();
          } else {
            (
              await this.toastCtrl.create({
                message: "Por favor, tente novamente",
                duration: 4000,
              })
            ).present();
          }
        }
      );
  }
}
