import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController, AlertController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Exercicio } from "../models/exercicio";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { NutriAlimentosService } from "./nutri-alimentos.service";
import { HelpersService } from "./helpers.service";

/*
  Generated class for the ExerciciosService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class NutriExerciciosService {
  loadingPaginate = false;
  listPaginate = new Array<Exercicio>();
  _getPaginate: Subscription;

  loadingDay = false;
  day = new Array<Exercicio>();
  totalDay = 0;
  totalMinutesDay = 0;
  _getDay: Subscription;

  constructor(
    public api: ApiService,
    public toastCtrl: ToastController,
    public helpers: HelpersService,
    public auth: AuthService,
    public _alimentos: NutriAlimentosService,
    public alertCtrl: AlertController
  ) {
    this.clearDay();
  }

  getPaginate(options: { page?; search? }, load = false) {
    if (this._getPaginate && !this._getPaginate.closed) {
      this._getPaginate.unsubscribe();
    }
    this.loadingPaginate = load;
    return this.api
      .get("/exercicios?page=" + options.page + "&search=" + options.search)
      .pipe(
        map((r: any) => {
          this.loadingPaginate = false;
          const array = r.data.map((obj) => new Exercicio(obj));
          this.listPaginate =
            options.page === 1 ? array : this.listPaginate.concat(array);
          return r;
        })
      );
  }

  getDay(clear = true) {
    return new Promise((resolve) => {
      if (this._getDay && !this._getDay.closed) {
        this._getDay.unsubscribe();
      }
      if (clear) {
        this.clearDay();
      }
      this.loadingDay = true;
      this._getDay = this.api
        .get(
          "/users/" +
            this.auth.user.user.id +
            "/exercicios?data=" +
            this._alimentos.selectedDate
        )
        .subscribe(
          (r: any) => {
            this.clearDay();
            this.loadingDay = false;
            this.day = r.map((obj) => new Exercicio(obj));
            this.day.forEach((obj) => {
              this.totalDay += obj.pivot.energia;
              this.totalMinutesDay += obj.pivot.quantidade;
            });
            resolve(true);
          },
          () => {
            resolve(true);
            this.loadingDay = false;
          }
        );
    });
  }

  clearDay() {
    this.day = [];
    this.totalDay = 0;
    this.totalMinutesDay = 0;
  }

  async delete(item) {
    (
      await this.alertCtrl.create({
        message: "Você tem certeza que deseja deletar esse exercício?",
        buttons: [
          {
            text: "Não",
            role: "cancel",
          },
          {
            text: "Sim",
            handler: () => {
              this.day = this.day.filter((obj) => obj.id != item.id);
              this.api
                .delete(
                  "/users/" + this.auth.user.user.id + "/exercicios/" + item.id,
                  { data: item.pivot.data }
                )
                .subscribe();
              this.getDay();
            },
          },
        ],
      })
    ).present();
  }
}
