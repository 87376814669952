import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { AplicativosService } from "./aplicativos.service";

/*
  Generated class for the CategoriasService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class NoticiasService {
  list: any = [];

  current: any = {};
  _getAll: Subscription;
  constructor(
    public api: ApiService,
    public toastCtrl: ToastController,
    public aplicativos: AplicativosService
  ) {
    console.log("Hello NoticiasService Service");
  }

  get(url?) {
    if (!url) {
      url = "/noticias";
    }
    return this.api.get(url);
  }

  getAll(options: any = {}) {
    const opened = this.list.find((noticias) => noticias.open);
    return new Promise((resolve, reject) => {
      if (this._getAll && !this._getAll.closed) {
        this._getAll.unsubscribe();
      }
      this._getAll = this.get("/noticias")
        .pipe(
          map((r: any) => {
            this.list = r;

            return this.list;
          })
        )
        .subscribe(
          (r: any) => resolve(r),
          (e) => reject(e)
        );
    });
  }
}
