import { Component } from "@angular/core";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";

@Component({
  selector: "page-refeicoes",
  template: `
    <ion-header>
      <header
        [showBackButton]="true"
        [defaultHref]="'/nutri-tabs'"
        [title]="_alimentos.tipos[this._alimentos.tipo]"
      ></header>
    </ion-header>

    <ion-content>
      <!-- @TODO IMPLEMENTAR REFRESHER E INFINITE SCROLL -->
      <app-refeicoes-content></app-refeicoes-content>
    </ion-content>
  `,
})
export class RefeicoesPage {
  constructor(public _alimentos: NutriAlimentosService) {}
}
