import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { CategoriasService } from "src/app/services/categorias.service";
import { PageParamsService } from "src/app/services/page-params.service";
/**
 * Generated class for the AudiosPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: "app-audios",
  templateUrl: "audios.component.html",
})
export class AudiosComponent implements OnInit {
  conteudos = [];
  audios = [];
  loading: boolean;
  constructor(
    public navCtrl: NavController,
    public pageParams: PageParamsService,
    public _categorias: CategoriasService
  ) {}
  async ngOnInit() {
    if (this._categorias.list.length == 0) {
      // this.loading = true;
      // this._categorias
      //   .getAll()
      //   .then(() => this.onlyAudios())
      //   .finally(() => (this.loading = false));
    } else {
      // this.onlyAudios();
    }
  }

  // onlyAudios() {
  //   this.audios = [...this._categorias.list].map((categoria) => {
  //     categoria.topicos = categoria.topicos.map((topico) => {
  //       topico.conteudos = topico.conteudos.filter(
  //         (conteudo) => conteudo.tipo == "audio"
  //       );

  //       topico.topicos = topico.topicos.map((topico2) => {
  //         topico2.conteudos = topico2.conteudos.filter(
  //           (conteudo) => conteudo.tipo == "audio"
  //         );
  //         return topico2;
  //       });

  //       return topico;
  //     });
  //     return categoria;
  //   });
  // }
}
