import { Component, Output, EventEmitter, Input } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { CategoriasService } from "src/app/services/categorias.service";
import { PlayerService } from "src/app/services/player.service";

/**
 * Generated class for the SublistItemDesktopComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "sublist-item-desktop",
  templateUrl: "sublist-item-desktop.html",
})
export class SublistItemDesktopComponent {
  @Output("favoritar") favoritarEvent = new EventEmitter<any>();
  @Input() item: any = {};
  @Input() forcarFavorito = false;

  text: string;

  constructor(
    public alertCtrl: AlertController,
    public player: PlayerService,
    public _categorias: CategoriasService
  ) {
    console.log("Hello SublistItemDesktopComponent Component");
    this.text = "Hello World";
  }

  playerPage() {
    this.player.playerPage(this.item);
  }

  async descricao() {
    (
      await this.alertCtrl.create({
        header: this.item.titulo,
        message: this.item.descricao.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        cssClass: "alert-lg",
        buttons: [
          {
            role: "cancel",
            text: "Ok",
          },
        ],
      })
    ).present();
  }

  favoritar() {
    this._categorias.favoritar(this.item).then(() => {
      this.favoritarEvent.next(this.item);
    });
    if (this.forcarFavorito) {
      this.item.favoritado = this.item.favoritado ? 0 : 1;
    }
  }
}
