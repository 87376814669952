import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { IonContent, MenuController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { NutriAlimentosService } from "src/app/services/nutri-alimentos.service";
import { PageParamsService } from "src/app/services/page-params.service";

type NutriTabs =
  | "refeicoes"
  | "controle-agua"
  | "home"
  | "exercicios"
  | "calculo-calorico";
@Component({
  templateUrl: "nutri-tabs.html",
})
export class NutriTabsPage implements AfterViewInit {
  @ViewChild(IonContent) content: IonContent;
  tab: NutriTabs = "home";
  queryParamsSub: Subscription;
  constructor(
    public pageParams: PageParamsService,
    public splashScreen: SplashScreen,
    public route: ActivatedRoute,
    public _alimentos: NutriAlimentosService,
    public _aplicativos: AplicativosService,
    private menu: MenuController 
  ) {}
  openMenu() {
    this.menu.enable(true, "first");
    this.menu.open("first");
  }
  async ngAfterViewInit() {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      if (params.tab) {
        this.setTab(params.tab);
      } else {
        this.setTab("home");
      }
    });
  }

  ionViewDidEnter() {
    this._aplicativos.setItem(this._aplicativos.getNutri());
  }

  setTab(tab: NutriTabs) {
    this.tab = tab;
  }
}
