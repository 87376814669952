import { AfterViewInit, Component, OnInit } from "@angular/core";
import {
  AlertController,
  NavController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { AuthService } from "src/app/services/auth.service";
import { LoadingService } from "src/app/services/loading.service";
import { UploadsService } from "src/app/services/uploads.service";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { ImagePicker } from '@ionic-native/image-picker/ngx';

@Component({
  selector: "app-perfil-content",
  templateUrl: "./perfil-content.component.html",
  styleUrls: ["./perfil-content.component.scss"],
})
export class PerfilContentComponent implements AfterViewInit {
  dados: any;
  barraObjetivo = 0;

  constructor(
    private alertCtrl: AlertController,
    public uploads: UploadsService,
    private auth: AuthService,
    private loading: LoadingService,
    private api: ApiService,
    private toastCtrl: ToastController,
    public plt: Platform,
    public aplicativos: AplicativosService,
    private camera: Camera,
    public imagePicker: ImagePicker
    
  ) {
    this.dados = Object.assign({}, this.auth.user.user);
    this.barraObjetivoChange(this.dados.objetivo_kg);
    console.log(this.aplicativos.item);
  }

  ngAfterViewInit() {
    console.log("ngAfterViewInit PerfilPage");
  }

  barraObjetivoChange(objetivo_kg) {
    this.dados.objetivo_kg = objetivo_kg;
    if (!this.dados.objetivo_kg) {
      this.dados.objetivo_kg = 1;
    }

    this.barraObjetivo = (this.dados.atual_kg / this.dados.objetivo_kg) * 100;

    if (this.dados.atual_kg > this.dados.objetivo_kg) {
      this.barraObjetivo = this.barraObjetivo - 100;
    }
  }

  objetivoKg() {
    // @todo verificar oq poderia ser feito aqui??
  }
  async onSubmit() {
    if (this.dados.inicio_kg) {
      if (!this.dados.objetivo_kcal) {
        (
          await this.toastCtrl.create({
            message: "Informe o seu objetivo (kcal)",
            duration: 4000,
          })
        ).present();
        return;
      }

      if (this.dados.objetivo_kg < 10 || !this.dados.objetivo_kg) {
        (
          await this.toastCtrl.create({
            message: "Informe o seu objetivo (kg)",
            duration: 4000,
          })
        ).present();
        return;
      }

      if (
        !this.dados.atual_kg ||
        !(this.dados.atual_kg < 300 && this.dados.atual_kg > 0)
      ) {
        (
          await this.toastCtrl.create({
            message: "Qual o seu peso?",
            duration: 3000,
          })
        ).present();
        return;
      }
      if (!this.dados.first_name) {
        (
          await this.toastCtrl.create({
            message: "Informe o seu nome",
            duration: 4000,
          })
        ).present();
        return;
      }
      if (!this.dados.telefone) {
        (
          await this.toastCtrl.create({
            message: "Informe o seu telefone",
            duration: 4000,
          })
        ).present();
        return;
      }
      if (!this.dados.telefone) {
        (
          await this.toastCtrl.create({
            message: "Informe o seu telefone",
            duration: 4000,
          })
        ).present();
        return;
      }
    }
    if (this.dados.password) {
      if (this.dados.password.length < 6) {
        (
          await this.toastCtrl.create({
            message: "Informe uma senha com pelo menos 6 caracteres",
            duration: 4000,
          })
        ).present();
        return;
      }
      if (this.dados.password != this.dados.confirmPassword) {
        (
          await this.toastCtrl.create({
            message: "As senhas não conferem, por favor tente novamente",
            duration: 400,
          })
        ).present();
        return;
      }
    } else {
      delete this.dados.password;
    }

    await this.loading.present();
    this.api.post("/users/" + this.auth.user.user.id, this.dados).subscribe(
      async (r: any) => {
        (
          await this.toastCtrl.create({
            message: "Dados alterados com sucesso!",
            duration: 4000,
          })
        ).present();
        this.loading.dismiss();
        this.auth.setToken(r.token);
        this.dados = this.auth.user.user;
      },
      (e) => this.loading.dismiss()
    );
  }

  async enviarFoto() {
    if (!this.plt.is("cordova")) {
      return;
    }
    const alert = await this.alertCtrl.create({
      header: "Alterar foto",
      buttons: [
        {
          text: "Galeria",
          handler: () => this.openGallery(),
        },
        {
          text: "Camera",
          handler: () => this.openCamera(),
        },
      ],
    });
    alert.present();
  }

  openGallery() {
    this.imagePicker
      .getPictures({
        quality: 80,
        width: 1280,
        maximumImagesCount: 1,
      })
      .then(async (r) => {
        await this.loading.present();
        this.uploads
          .startMultipleUpload(
            r,
            "/users/" + this.auth.user.user.id + "/avatar"
          )
          .then((r) => {
            this.auth.setToken(JSON.parse(r[0].response).token);
            this.dados = this.auth.user.user;
          })
          .catch((e) => console.error("multiupload error", e))
          .finally(() => this.loading.dismiss());
      })
      .catch((e) => console.error("imagePicker error", e));
  }

  openCamera() {
    const options: CameraOptions = {
      quality: 80,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
    };
    this.camera
      .getPicture(options)
      .then(async (r) => {
        await this.loading.present();
        this.uploads
          .startMultipleUpload(
            r,
            "/users/" + this.auth.user.user.id + "/avatar"
          )
          .then((r) => {
            this.auth.setToken(JSON.parse(r[0].response).token);
            this.dados = this.auth.user.user;
          })
          .finally(() => {
            this.loading.dismiss();
          });
      })
      .catch((e) => console.error(" openCamera => e", e));
  }
}
