export class Exercicio {
  [x: string]: any;
  constructor(obj?) {
    Object.assign(this, obj);
    this.energia = this.energia ? parseInt(this.energia) : 0;

    if (this.pivot) {
      this.pivot.energia = Math.ceil(
        (this.energia * this.pivot.quantidade) / 60
      );
    }
  }
}
